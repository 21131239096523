import { createSlice } from '@reduxjs/toolkit';
import { category } from './API';
// import { offers } from './API';

export const HomeSlice = createSlice({
  name: 'products',
  initialState: {
    favoritse: [],
    product: null,
    showR: false,
    categoryD: null,
    searsh: false,
    
  },
  reducers: {
    addFavorites: (state, action) => {
      state.favoritse = action.payload.favoritse;
    },
    productDetails: (state, action) => {
      state.product = action.payload;
    },
    showRegister: (state, action) => {
      state.showR = action.payload;
    },
    category_data: (state, action) => {
      state.categoryD = action.payload;
    },
    searsh_product: (state, action) => {
      state.searsh = action.payload;
    },
    
  },
  
});

export const {addFavorites, productDetails, showRegister, category_data, searsh_product} = HomeSlice.actions;
export default HomeSlice.reducer;
