import { faHeart, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { useSelector, useDispatch } from 'react-redux';
import Loader from "../Loader";
import {showRegister} from "../Redux/HomeSlice";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import Register from '../Register/Register';
import { get_cart, myProfile, add_wishlist, remove_wishlist, product_reviews, trending } from "../Redux/API";
import { productDetails } from "../Redux/HomeSlice";

import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../Offers/Offers.css'
import './Trending.css'

function Trending() {
  const dispatch = useDispatch();
  const navigate = useNavigate();


    useEffect(() => {
        dispatch(trending())
    }, [])


    

  const getLocalStorage = window.localStorage.getItem('UserInfo');
  const valueLocalStorage = getLocalStorage ? JSON.parse(getLocalStorage) : null;
  const token = valueLocalStorage?.data?.token || null;

  const showR = useSelector(state => state?.products?.showR);

    const wishlist = useSelector(state => state?.Data?.myProfile?.data?.data?.user?.wishlist || []);
    const loadingRemove_wishlist = useSelector((state) => state?.Data.loadingRemove_wishlist);
    const loadingAdd_wishlist = useSelector((state) => state?.Data.loadingAdd_wishlist);
     
    useEffect(() => {
        if (!loadingRemove_wishlist && !loadingAdd_wishlist) {
            dispatch(myProfile()); 
        }
    }, [dispatch, loadingRemove_wishlist, loadingAdd_wishlist]);
    
    const handelAdd_wishlist = (id) => {
        if (token) {
            const isInWishlist = wishlist.some(item => item.id === id);
    
            if (isInWishlist) {
                dispatch(remove_wishlist({ id })); 
            } else {
                dispatch(add_wishlist({ id })); 
            }
        } else {
            dispatch(showRegister(true));
        }
    };

    const trend = useSelector(state => state.Data.trending);
    const loadingTrending = useSelector(state => state.Data.loadingTrending);
    const images = trend?.data?.data || [];


    
    const gotoProductDetails = (product) => {
        if (!product || !product?.id) {
          return;
        }
        dispatch(productDetails(product));
        dispatch(product_reviews(product?.id));
    
        navigate('/Product');
      };

    
    return (
        <>
            <Header />
            {showR && <Register />}
            <div className='container offers'>
                {loadingTrending && <Loader />}
                <div className='cont row justify-content-between g-0 border-0'>
                    <h3 className='title'>Trending</h3>
                    <div className=' col'>
                        <div className="gallery1 container 0 border-0">
                            <div className="row_prodect row  align-items-center justify-content-around g-0 border-0">
                            {images.map((image, index) => {
                                const isInWishlist = wishlist.some(item => item.id === image.id);

                                return (
                                    <div key={index} className="box col-5 col-sm-4 px-sm-2 col-md-4 px-md-3 col-lg-3 px-lg-3 mx-lg-0 col-xl-2 mx-xl-1 px-xl-0 text-center g-0 mb-3">
                                    <div className="border_box">
                                        <div className="padding_box">
                                        <i
                                            className="font-size2"
                                            onClick={() => handelAdd_wishlist(image.id)}
                                            style={{ color: isInWishlist ? 'red' : 'white' }}
                                        >
                                            <FontAwesomeIcon icon={faHeart} />
                                        </i>
                                        {image?.discount && <p className="discount">Off {image?.discount}</p>}
                                            <img src={image.main_image} alt={`img-${index}`} onClick={() => gotoProductDetails(image)} />
                                        <p className="discraptoin">{image?.description}</p>
                                        <div className="price align-items-center">
                                            <p>{image?.new_price}</p>
                                            {image?.old_price && <p className="old_price">{image?.old_price}</p>}
                                        </div>
                                        </div>
                                    </div>
                                    </div>
                                );
                            })}
                            </div>
                        </div>
                    </div>

                
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Trending;