import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import Brand from './Brand/Brand';
import Main from './Main/Main';
import SubSupCategory from './SubSupCategory/SubSupCategory';
import Home from './Home/Home';
import Products from './Products/Products';
import Product from './Product/Product';
import Purchasing from './Purchasing/Purchasing';
import Profile from './Profile/Profile';
import Header from './Header/Header';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import Offers from './Offers/Offers';
import Favorites from './Favorites/Favorites';
import NewArriva from './NewArrival/NewArriva';
import Influencer from './Influencer/Influencer';
import Store from './Stores/Store';
import Trending from './Trending/Trending';

import { useEffect, useState, useReducer } from 'react';

function App() {
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
  // const [, forceUpdate] = useReducer(x => x + 1, 0); // لإجبار تحديث المكونات

 
  useEffect(() => {
    document.documentElement.setAttribute('dir', language === 'ar' ? 'rtl' : 'ltr');
    localStorage.setItem('language', language);
    // forceUpdate(); // تحديث المكونات بعد تغيير اللغة
  }, [language]);



  const [routeHistory, setRouteHistory] = useState([]);
  const location = useLocation();
  useEffect(() => {
    setRouteHistory(prevHistory => {
      // لو الـ route الحالي هو آخر route في التاريخ، ما نضفوش
      if (prevHistory.length > 1 && prevHistory[prevHistory.length - 2] === location.pathname) {
        return prevHistory.slice(0, -1);
      }
      return [...prevHistory, location.pathname];
    });
  }, [location]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home routeHistory={routeHistory} />} />
        <Route path="/" element={<Header routeHistory={<Header/>} />} />
        <Route path="/Main" element={<Main routeHistory={routeHistory} />} />
        <Route path="/SubSupCategory" element={<SubSupCategory routeHistory={routeHistory} />} />
        <Route path="/Brand" element={<Brand routeHistory={routeHistory} />} />
        <Route path="/Products" element={<Products routeHistory={routeHistory} />} />
        <Route path="/Product" element={<Product routeHistory={routeHistory} />} />
        <Route path="/Purchasing" element={<Purchasing routeHistory={routeHistory} />} />
        <Route path="/Profile" element={<Profile routeHistory={routeHistory} />} />
        <Route path="/ForgotPassword" element={<ForgotPassword routeHistory={routeHistory} />} />
        <Route path="/Offers" element={<Offers routeHistory={routeHistory} />} />
        <Route path="/Favorites" element={<Favorites routeHistory={routeHistory} />} />
        <Route path="/NewArriva" element={<NewArriva routeHistory={routeHistory} />} />
        <Route path="/Influencer" element={<Influencer routeHistory={routeHistory} />} />
        <Route path="/Store" element={<Store routeHistory={routeHistory} />} />
        <Route path="/Trending" element={<Trending routeHistory={routeHistory} />} />
      </Routes>
    </div>
  );
}

export default App;