import './Purchasing.css';
import icon9 from "../Icons/Icon-09.png";
import {showRegister} from "../Redux/HomeSlice";
import { productDetails } from "../Redux/HomeSlice";

import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useState, useEffect } from 'react';
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Loader from "../Loader";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { get_cart, myProfile, add_wishlist, remove_wishlist, product_reviews, delete_cart } from "../Redux/API";
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { useTranslation } from 'react-i18next';


function Purchasing() {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const loadingDelete_cart = useSelector((state) => state?.Data.loadingDelete_cart);

  const getLocalStorage = window.localStorage.getItem('UserInfo');
  const valueLocalStorage = getLocalStorage ? JSON.parse(getLocalStorage) : null;
  const token = valueLocalStorage?.data?.token || null;

  const showR = useSelector(state => state?.products?.showR);
  const get_carts = useSelector(state => state.Data.get_cart);
  const loadingGet_cart = useSelector(state => state.Data.loadingGet_cart);

  console.log(get_carts);

  useEffect(() => {
    dispatch(get_cart());
  }, [dispatch, loadingDelete_cart]);

  const [counter, setCounter] = useState(1);
  
  const increment = () => {
    setCounter(prevCounter => prevCounter + 1);
  };

  const decrement = () => {
    setCounter(prevCounter => (prevCounter > 1 ? prevCounter - 1 : 1));
  };

  const wishlist = useSelector(state => state?.Data?.myProfile?.data?.data?.user?.wishlist || []);
  const loadingRemove_wishlist = useSelector((state) => state?.Data.loadingRemove_wishlist);
  const loadingAdd_wishlist = useSelector((state) => state?.Data.loadingAdd_wishlist);
   
  useEffect(() => {
      if (!loadingRemove_wishlist && !loadingAdd_wishlist) {
          dispatch(myProfile()); 
      }
  }, [dispatch, loadingRemove_wishlist, loadingAdd_wishlist]);
  
  const handelAdd_wishlist = (id) => {
      if (token) {
          const isInWishlist = wishlist.some(item => item.id === id);
  
          if (isInWishlist) {
              dispatch(remove_wishlist({ id })); 
          } else {
              dispatch(add_wishlist({ id })); 
          }
      } else {
          dispatch(showRegister(true));
      }
  };

  const recommendeds = useSelector(state => state.Data.recommended);
  const loadingRecommended = useSelector(state => state.Data.loadingRecommended);
  const gotoProductDetails = (product) => {
    if (!product || !product?.id) {
      return;
    }

    // إرسال البيانات إلى Redux
    dispatch(productDetails(product));
    dispatch(product_reviews(product?.id));

    // التنقل إلى صفحة التفاصيل
    navigate('/Product');
  };
  const imagess1 = recommendeds?.data?.data || [];

  const [startIndex1, setStartIndex1] = useState(0);

  const handleNext1 = () => {
    if (startIndex1 + 5 < imagess1?.length) {
      setStartIndex1(startIndex1 + 1);
    }
  };

  const handlePrev1 = () => {
    if (startIndex1 > 0) {
      setStartIndex1(startIndex1 - 1);
    }
  };

  const getVisibleImages1 = () => {
    if (window.innerWidth >= 1400) {
      return imagess1?.slice(startIndex1, startIndex1 + 5);
    } else if (window.innerWidth >= 1200) {
      return imagess1?.slice(startIndex1, startIndex1 + 5);
    } else if (window.innerWidth >= 992) {
      return imagess1?.slice(startIndex1, startIndex1 + 4);
    } else if (window.innerWidth >= 768) {
      return imagess1?.slice(startIndex1, startIndex1 + 3);
    } else if (window.innerWidth >= 576) {
      return imagess1?.slice(startIndex1, startIndex1 + 3);
    } else {
      return imagess1?.slice(startIndex1, startIndex1 + 2);
    }
  };

  const delete_cartS = (id) => {
    dispatch(delete_cart(id));
  };

  return (
    <div className="Purchasing">
      <Header />

      {loadingDelete_cart && <Loader />}
      {loadingGet_cart && <Loader />}
      <div className='container_Purchasing container mt-m-2'>
        {get_carts?.data?.data?.items.map((e) => 
          <div className='container_prodects row justify-content-between' key={e.id}>
            <div className='col-12 col-md-6 col-lg-4 text-center'>
              <img src={e.main_image} alt="product"/>
            </div>
            <div className='details col-12 col-md-6 col-lg-4'>
              <p>{e.nike}</p>
              <p>Color</p>
              <div className=' d-flex'>
                {e?.colors.map((e) =>
                <div className='img_color'>
                  <img src={e.colors} />
                </div>
                )}
              </div>
              <p className=' mt-3'>Size</p>
              <div className='d-flex'>
                {e?.sizes.map((e) =>
                <div className='img_color d-flex'>
                  <span>{e.size}</span>
                </div>
                )}
              </div>
              
            </div>
            <div className='box_counter col-12 col-md-6 col-lg-4 mt-sm-3 text-center'>
              <div className="counter">
                <span className='span1' onClick={decrement}>-</span>
                <span className='span3'>{e.quantity}</span>
                <span className='span2' onClick={increment}>+</span>
              </div>
              <i ><FontAwesomeIcon icon={faTrash} onClick={() => delete_cartS(e.id)}/></i>
              <p><span>KWD</span>{e.new_price}</p>
            </div>
          </div>
        )}
      </div>

      <div className='Vouchers container'>
        <div className='row justify-content-end'>
          <div className='Quantity col col-md-6'>
            <div>
              <p>Quantity</p>
              <p className='p2'>2</p>
              <p>TotalPrice</p>
              <p className='p3'><span>KWD</span> 26.400</p>
            </div>
            <div className='Promos'>
              <span>Promos & Vouchers</span>
              <span>ASHTIRI 1</span>
            </div>
            <div className='Promos2'>
              <span>Discount Voucher</span>
              <span className='Discount'>-<span className='KWD'>KWD</span> 13.200</span>
            </div>
            <div className='Promos2'>
              <span>Shipping Fee</span>
              <span><span className='KWD'>KWD</span> 1.500</span>
            </div>
            <div className='Promos'>
              <span>Total Price</span>
              <span><span className='KWD'>KWD</span> 14.700</span>
            </div>
            <div>Checkout Now</div>
            <div>Ways you can pay:</div>
            <div>
              {/* <img src={img3} alt="payment"/>
              <img src={img3} alt="payment"/>
              <img src={img3} alt="payment"/>
              <img src={img3} alt="payment"/>
              <img src={img3} alt="payment"/> */}
            </div>
          </div>
        </div>
      </div>
        
      <div className="container">
        <p>For New Customers</p>
      </div>

      <div className="gallery1 container">
      {loadingRecommended && <Loader />}

  <div className="col-1 text-center icon2">
  <img src={icon9} className="iconLeft" onClick={handlePrev1} />
  </div>
  <div className="row align-items-center justify-content-between g-0">
  {(getVisibleImages1()).map((product, index) => {
    const isInWishlist = wishlist.some(item => item.id === product.id); // تحقق من وجود المنتج في الوشليست

    return (
        <div className="box col-6 px-3 col-sm-4 px-sm-2 col-md-4 px-md-3 col-lg-3 col-xl-2 px-xl-0 mx-xl-0 text-center" key={index}>
            <div className="border_box">
                <div className="padding_box">
                    <i
                        onClick={() => {
                            handelAdd_wishlist(product.id);
                        }}
                        style={{ color: isInWishlist ? 'red' : 'white' }} // تغيير لون الأيقونة
                    >
                        <FontAwesomeIcon icon={faHeart} />
                    </i>
                    {product?.discount && <p className="discount">Off {product?.discount}</p>}
                    {product?.main_image &&<img onClick={() => gotoProductDetails(product)} src={product?.main_image} alt={`img-${index}`} />}
                    <p className="discraptoin">{product?.description}</p>
                    <div className="price align-items-center">
                        <p>{product?.new_price}</p>
                        {product?.old_price && <p className="old_price">{product?.old_price}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
})}
  </div>
  <div className="col-1 text-center icon2">
    <img src={icon9} className="iconRitgh" onClick={handleNext1} />
  </div>
</div>

      <Footer />
    </div>
  );
}

export default Purchasing;