import React, { useState, useCallback } from 'react';
import { faChevronRight, faCloudUpload, faHeart, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import icon1 from '../Icons/Groaxup.png';
import img6 from '../images/IMG-20240723-WA0022.jpg';
import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Loader from "../Loader";

import './Profile.css';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';
import { summary, get_user_return, get_user_exchange, myProfile, unfollow, remove_wishlist, return_orders, exchange, change_assword, change_personal_info} from "../Redux/API";
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function Profile() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();


    const gotoProductDetails = (product) => {
        if (!product || !product.id) {
          console.error('Product data is missing or invalid');
          return;
        }
    }


    useEffect(() => {
        dispatch(summary())
        dispatch(get_user_return())
        dispatch(get_user_exchange())
    }, [])


    // const summarys = useSelector(state => state.Data);
    const summaryw = useSelector(state => state?.Data?.summary);
    const get_user_returns = useSelector(state => state?.Data?.get_user_return);
    const get_user_exchanges = useSelector(state => state?.Data.get_user_exchange);


    const myProfiles = useSelector(state => state?.Data.myProfile);
    console.log(get_user_exchanges)
    console.log(get_user_returns)
    console.log(myProfiles)



    
    const DataList = [
        { id: 1, account: t('myAccount') },
        { id: 2, account: t('myOrders') },
        { id: 3, account: t('trackOrders') },
        { id: 4, account: t('returnsExchanges') },
        { id: 5, account: t('wishlistInfluencer') },
        { id: 6, account: t('myPoints') }
      ];

    const [activeIcon, setActiveIcon] = useState(1);
    const [visibleSection, setVisibleSection] = useState(1);

    function handleClick(id) {
        setActiveIcon(activeIcon === id ? null : id);
        setVisibleSection(id);
    }

    const [showList, setShowList] = useState(true);
    function toggleList() {
        setShowList(!showList);
    }


  // الحالة لعرض الصورة من الباك اند أو تركها فارغة
  const [singleImage, setSingleImage] = useState(
    myProfiles?.data.data.user.user_image ? [{ preview: myProfiles?.data.data.user.user_image }] : []
  );
  const [errorSingleImage, setErrorSingleImage] = useState('');
  // دالة لتحميل الصورة الواحدة
  const onDropSingle = useCallback((acceptedFiles) => {
    const imageFiles = acceptedFiles.filter(file => file.type.startsWith('image/'));

    if (imageFiles.length > 0) {
      // استبدال الصورة القديمة بالجديدة
      setSingleImage(imageFiles.map(file => Object.assign(file, {
        preview: URL.createObjectURL(file)
      })));
      setErrorSingleImage('');
    } else {
      setErrorSingleImage('Please upload only images');
    }
  }, []);

  // استخدام useDropzone
  const { getRootProps: getRootPropsSingle, getInputProps: getInputPropsSingle } = useDropzone({
    onDrop: onDropSingle,
    multiple: false
  });
    
        const [firstname, setFirstname] = useState('');
        const [lastname, setLastname] = useState('');
        const [date, setDate] = useState(null);
        const [gender, setGender] = useState(null);
        const [number, setNumber] = useState(null);
        const [nationality, setNationality] = useState('');
        const [address, setAddress] = useState('');
        const [language, setLanguage] = useState(null); // اللغة المختارة
        const [email, setEmail] = useState('');
        const [password, setPassword] = useState('');
        const [new_password, setNew_password] = useState('');
        const [password_confirmation, setPassword_confirmation] = useState('');
        const [error, setError] = useState(''); // لحفظ رسالة الخطأ
        const [isValid, setIsValid] = useState(false);

          // استخدم useEffect لتعيين البيانات عند تحميلها
        useEffect(() => {
            if (myProfiles?.data?.data?.user) {
            setFirstname(myProfiles?.data.data.user.first_name);
            setLastname(myProfiles?.data.data.user.last_name);
            setDate(myProfiles?.data.data.user.date_of_birth); // Assuming there's a date field
            setGender(myProfiles?.data.data.user.gender);
            setNumber(myProfiles?.data.data.user.phone_number);
            setNationality(myProfiles?.data.data.user.nationality);
            setAddress(myProfiles?.data.data.user.address);
            setLanguage(myProfiles?.data.data.user.preferred_language);
            setEmail(myProfiles?.data.data.user.email);
            }
        }, [myProfiles]);

        const change_personal_inf = useSelector(state => state?.Data?.change_personal_info);
        console.log(myProfiles?.data?.data?.user)
        const loadingChange_personal_inf = useSelector((state) => state?.Data.loadingChange_personal_info);

        console.log(change_personal_inf)
          // دالة التعامل مع Submit
        const handleSubmitProfile = (event) => {
            event.preventDefault(); 
            dispatch(change_personal_info({firstname, lastname, date, gender, number, nationality, address, language, singleImage}))
        };

        const handleIconClick = () => {
            window.localStorage.removeItem('UserInfo');
            navigate('/')
        };

        const loadingChange_assword = useSelector((state) => state?.Data.loadingChange_assword);




        const [visibleOrderId, setVisibleOrderId] = useState(null);

        // دالة للتبديل بين إظهار وإخفاء الطلب
        const toggleOrderVisibility = (orderId) => {
          if (visibleOrderId === orderId) {
            // لو الضغط على نفس الـ id، نخفيه
            setVisibleOrderId(null);
          } else {
            // لو الضغط على طلب آخر، نظهره
            setVisibleOrderId(orderId);
          }
        };
      
    
        const validatePasswords = () => {
            if (new_password.length < 8) {
              setError('يجب أن تكون كلمة المرور الجديدة 8 أحرف على الأقل.');
              return false;
            }
            if (!/\d/.test(new_password) || !/[a-zA-Z]/.test(new_password)) {
              setError('يجب أن تحتوي كلمة المرور الجديدة على حروف وأرقام.');
              return false;
            }
            if (new_password !== password_confirmation) {
            //   setError('كلمة المرور وتأكيد كلمة المرور غير متطابقين.');
              return false;
            }
            setError('');
            return true;
          };
        
          const handleChange = () => {
            // تحقق من صحة الحقول
            const valid = validatePasswords();
            setIsValid(valid);
          };
        
          const Change_Password = () => {
            // إذا كانت الحقول صحيحة
            if (validatePasswords()) {
              dispatch(change_assword({ password, new_password, password_confirmation }));
            }
        };



        const handleSubmit = (e) => {
            e.preventDefault();
            if (isValid) {
                // إرسال البيانات إلى الباكند هنا
                console.log("بيانات صالحة، تم الإرسال!");
            } else {
                alert("تأكد من صحة البيانات قبل الإرسال");
            }
        };


        const handelUnfollow = (id) => {
            dispatch(unfollow({ id }));
        };
        
        const handelremove_wishlist = (id) => {
            dispatch(remove_wishlist({ id }));
        };


        const loadingExchange = useSelector((state) => state?.Data.loadingExchange);
        const loadingReturn_orders = useSelector((state) => state?.Data.loadingReturn_orders);
        
        const handelreturn_orders = (id) => {
            dispatch(return_orders({ order_id: id }));
        };

        const handelExchange = (id) => {
            dispatch(exchange({ order_id: id }));
        };
        
        
        const [orderNumber, setOrderNumber] = useState(); // هنحتفظ برقم الأوردر هنا
        const handleInputChange = (e) => {
            setOrderNumber(e.target.value); 
          };
        const [orderNumber_Returns, setOrderNumber_Returns] = useState(); // هنحتفظ برقم الأوردر هنا
        const handleInputChangeReturns = (e) => {
            setOrderNumber_Returns(e.target.value); 
          };
        
        

        const loadingMyProfile = useSelector((state) => state?.Data.loadingMyProfile);
        const loadingUnfollow = useSelector((state) => state?.Data.loadingUnfollow);
        const loadingRemove_wishlist = useSelector((state) => state?.Data.loadingRemove_wishlist);
        

        useEffect(() => {
        if (!loadingUnfollow && !loadingRemove_wishlist) {
            dispatch(myProfile(orderNumber));
        }
        }, [dispatch, loadingUnfollow, loadingRemove_wishlist, orderNumber]);




        const [counter, setCounter] = useState(1);
        const increment = () => {
          setCounter(prevCounter => prevCounter + 1);
        };
      
        const decrement = () => {
          setCounter(prevCounter => (prevCounter > 1 ? prevCounter - 1 : 1));
        };
    


        const [show_Returns_Exchanges, setShow_Returns_Exchanges] = useState(false)
        const Returns_Exchanges = () => {
            setOrderNumber_Returns(null)
            setShow_Returns_Exchanges(!show_Returns_Exchanges)
          };
      
    return (
        <>
            <Header />
            
        <div className="Profile">
            <div className='container g-0'>
                <div className='cont row justify-content-between g-0'>
                    <div className={`cont_list col-4 g-0 ${showList ? 'active' : ''}`}>
                        <div className='toggle-button' onClick={toggleList}>
                            {showList ? <i><FontAwesomeIcon icon={faTimes}/></i> : <i><FontAwesomeIcon icon={faChevronRight}/></i>}
                        </div>
                        <div>
                            <div className='name'>
                                <div className='div1'>
                                    <img src={myProfiles?.data.data.user.user_image} alt="Profile" />
                                </div>
                                <div className='div2'>
                                    <h5>{firstname}</h5>
                                    <p>( {summaryw?.data?.data?.available_balance} {t('Points')})</p>
                                </div>
                            </div>
                            {/* <span>Signed in via Google account</span> */}
                        </div>
                        <>
                            {DataList.map((e) => (
                                <div className='borders' key={e.id} onClick={() => handleClick(e.id)}>
                                    <div>
                                        <p>{e.account}</p>
                                        <i className={activeIcon === e.id ? 'activ' : 'noActiv'}>
                                            <img src={icon1} alt="Toggle" />
                                        </i>
                                    </div>
                                </div>
                            ))}
                        </>
                    </div>
                    {visibleSection === 5 && (
                    <div className='wishlist col'>
                        <h3 className='title'>{t('Wishlist')}</h3>
                        <div className="gallery1 container">
                            <div className="row_prodect row align-items-center justify-content-around g-0">
                                {loadingRemove_wishlist && <Loader />}
                                {myProfiles?.data.data.user.wishlist.map((product, index) => (
                                    <div key={index} className="box col-5 col-sm-4 px-sm-1 px-md-2 col-md-6 col-lg-4 px-lg-2 px-xl-4 text-center g-0 mb-3">
                                        <div className="border_box">
                                            <div className="padding_box">
                                                <i><FontAwesomeIcon icon={faTimes} onClick={() => handelremove_wishlist(product.id)} /></i>
                                                {product?.discount && <p className="discount">{t('Off')} {product?.discount}</p>}
                                                {product.main_image && <img onClick={() => gotoProductDetails(product)} src={product.main_image} alt={`img-${index}`} />}
                                                <p className="discraptoin">{product?.description}</p>
                                                <div className="price align-items-center">
                                                    <p>{product?.new_price}</p>
                                                    {product?.old_price && <p className="old_price">{product?.old_price}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>

                            <div className="row_prodect2 row align-items-center justify-content-around g-0">
                                {loadingUnfollow && <Loader />}
                                {myProfiles?.data.data.user.following_influencers.map((influencer, index) => (
                                    <div key={index} className="box box_cont col-5 col-sm-4 px-sm-1 px-md-1 px-lg-2 px-xl-3 text-center g-0 mb-3">
                                        <div className='group mb-3'>
                                            <div className="border_box">
                                                <div className="padding_box">
                                                    <i className='font-size2' onClick={() => handelUnfollow(influencer.id)}><FontAwesomeIcon icon={faTimes} /></i>
                                                    <Link to={"/Product"}>
                                                        <img src={influencer.image} alt={`img-${index}`} />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="price_cont align-items-center">
                                                <p>{influencer.name}</p>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}


                    {visibleSection === 2 && (
                        <div className='col Orders'>
                            <h3 className='title'>{t('My Orders')}</h3>
                            <div className='container'>
                                <div className='points2 row align-items-center justify-content-end g-0'>
                                    {myProfiles?.data.data.user.orders.map((order, index) => (
                                        <div className='col-11 col-md-10 col-lg-10' key={index}>
                                            <div className='group_data'>
                                                <div>
                                                    <p>{t('Order')}</p>
                                                    <p><span>{order?.order_code}</span></p>
                                                </div>
                                                <div>
                                                    <p>{t('Date')}</p>
                                                    <p>{order?.order_date}</p>
                                                </div>
                                                <div>
                                                    <p>{t('Status')}</p>
                                                    <p>{order?.order_status}</p>
                                                </div>
                                                <div>
                                                    <p>{t('Total')}</p>
                                                    <p><span>{order.total_price} KWD</span> {t('For')} <span>{order?.num_of_items} {t('Item')}</span></p>
                                                </div>
                                            </div>
                                            <div className='Actions'>
                                                <p>{t('Actions')}</p>
                                                <p className={visibleOrderId === order.id ? 'btn' : 'btn_active'} onClick={() => toggleOrderVisibility(order.id)}>{t('View')}</p>
                                            </div>

                                            {visibleOrderId === order.id && (
                                                <div className='container_Purchasing container'>
                                                    {order.order_items.map((items) => (
                                                        <div className='container_prodects row justify-content-between' key={items.id}>
                                                            <div className='col-12 col-md-6 text-center'>
                                                                <img src={items.product_image} alt={items.nike} />
                                                            </div>
                                                            <div className='details col-12 col-md-6'>
                                                                <p>{items.product}</p>
                                                                <p>{t('Color')}: {items.product_color}</p>
                                                                <p>{t('Size')}: {items.product_size}</p>
                                                            </div>
                                                            <div className='box_counter col-12 mt-3 text-center'>
                                                                <div className="counter">
                                                                    <span className='span1' onClick={decrement}>-</span>
                                                                    <span className='span3'>{items.quantity}</span>
                                                                    <span className='span2' onClick={increment}>+</span>
                                                                </div>
                                                                <i><FontAwesomeIcon icon={faTrash} /></i>
                                                                <p><span>KWD</span> {items.product_price}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}

                                            <div className='Vouchers container'>
                                                <div className='row justify-content-end'>
                                                    <div className='Quantity col-12'>
                                                        <div>
                                                            <p>{t('Total Quantity')}</p>
                                                            <p className='p2'>{order?.total_quantity}</p>
                                                            <p>{t('Total Price')}</p>
                                                            <p className='p3'><span>KWD </span>{order?.total_price}</p>
                                                        </div>
                                                        <div className='Promos'>
                                                            <span>{t('Promos & Vouchers')}</span>
                                                            <span>ASHTIRI {order?.order_id}</span>
                                                        </div>
                                                        <div className='Promos2'>
                                                            <span>{t('Discount Voucher')}</span>
                                                            <span className='Discount'>-<span className='KWD'>KWD</span> {order?.total_discount}</span>
                                                        </div>
                                                        <div className='Promos2'>
                                                            <span>{t('Shipping Fee')}</span>
                                                            <span><span className='KWD'>KWD</span> {order?.shipping_cost}</span>
                                                        </div>
                                                        <div className='Promos'>
                                                            <span>{t('Total Price')}</span>
                                                            <span><span className='KWD border-0'>KWD </span>{order?.total_price}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    )}

                    {visibleSection === 6 && (
                        <div className='col myPounts'>
                            <h3 className='title'>{t('My Points')}</h3>
                            <div className='container'>
                                <div className='points row align-items-center justify-content-around g-0'>
                                    <div className='col-11 col-md-10 col-lg-8'>
                                        <div className='points_img'>
                                            <div className='div1'>
                                                <img src={myProfiles?.data.data.user.user_image} />
                                            </div>
                                            <p>{firstname}</p>
                                        </div>
                                        <div className='borders2'>
                                            <div className='points_data'>
                                                <div className='div1_points'>
                                                    <p>{t('YOUR POINTS')}</p>
                                                </div>
                                                <div className='div2_points'>
                                                    <div>
                                                        <p>{t('Earned this month')}</p>
                                                        <p className='p'>({summaryw?.data?.data?.earned_this_month} {t('points')})</p>
                                                    </div>
                                                    <div>
                                                        <p>{t('Ends this month')}</p>
                                                        <p className='p'>({summaryw?.data?.data?.ends_this_month} {t('points')})</p>
                                                    </div>
                                                </div>
                                                <div className='div3_points'>
                                                    <p>{t('Available balance')} = </p>
                                                    <div>
                                                        <span>KWD</span>
                                                        <span>{summaryw?.data?.data?.available_balance}</span>
                                                    </div>
                                                </div>
                                                <div className='div4_points'>
                                                    <p>{t('Buy more and earn points')}</p>
                                                    <p>{t('One point is equal to one dinar')}</p>
                                                    <p>{t('Points expire after one year')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {visibleSection === 1 && (
                        <div className='col myAccount'>
                            {loadingChange_assword && <Loader />}
                            {loadingChange_personal_inf && <Loader />}
                            <h3 className='title'>{t('Personal Information')}</h3>

                            <div className='container'>
                                <div className='points2 row align-items-center justify-content-end g-0'>
                                    <div className='col-11 col-md-10 col-lg-10 g-0 '>
                                        <form onSubmit={handleSubmitProfile}>
                                            {/* First Name */}
                                            <div className='div1'>
                                                <label htmlFor='firstname'>{t('First Name')}</label>
                                                <input type='text' id='firstname' name='firstname' value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                                            </div>

                                            {/* Last Name */}
                                            <div className='div1'>
                                                <label htmlFor='lastname'>{t('Last Name')}</label>
                                                <input type='text' id='lastname' name='lastname' value={lastname} onChange={(e) => setLastname(e.target.value)} />
                                            </div>

                                            {/* Date Of Birth */}
                                            <div className='div1'>
                                                <label htmlFor='date'>{t('Date Of Birth')}</label>
                                                <input type='date' id='date' name='date' value={date} onChange={(e) => setDate(e.target.value)} />
                                            </div>

                                            {/* Gender */}
                                            <div className='Gender'>
                                                <label className='label'>{t('Gender')}</label>
                                                <div className='group_Gender'>
                                                    <div className='radio col-6'>
                                                        <input type='radio' id='female' name='gender' value='Female' onChange={(e) => setGender(e.target.value)} />
                                                        <label htmlFor='female'>{t('Female')}</label>
                                                    </div>
                                                    <div className='radio col-6'>
                                                        <input type='radio' id='male' name='gender' value='Male' onChange={(e) => setGender(e.target.value)} />
                                                        <label htmlFor='male'>{t('Male')}</label>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* اختيار اللغة */}
                                            <div className='div1'>
                                                <label htmlFor='language'>{t('Choose Language')}</label>
                                                <select id='language' name='language' value={language} onChange={(e) => setLanguage(e.target.value)}>
                                                    <option value='arabic'>{t('Ar')}</option>
                                                    <option value='english'>{t('En')}</option>
                                                </select>
                                            </div>

                                            {/* Number */}
                                            <div className='div1'>
                                                <label htmlFor='number'>{t('Number')}</label>
                                                <input type='number' id='number' name='number' value={number} onChange={(e) => setNumber(e.target.value)} />
                                            </div>

                                            {/* Nationality */}
                                            <div className='div1'>
                                                <label htmlFor='nationality'>{t('Nationality')}</label>
                                                <input type='text' id='nationality' name='nationality' value={nationality} onChange={(e) => setNationality(e.target.value)} />
                                            </div>

                                            {/* Address */}
                                            <div className='div1'>
                                                <label htmlFor='address'>{t('Address')}</label>
                                                <textarea value={address} onChange={(e) => setAddress(e.target.value)}></textarea>
                                            </div>

                                            {/* Image */}
                                            <div className="div1">
                                                <label className="title">{t('Image')}</label>
                                                <div className="Add_img">
                                                    {errorSingleImage && <p className="error">{errorSingleImage}</p>}
                                                    <div className="adds" {...getRootPropsSingle()}>
                                                        <input {...getInputPropsSingle()} />
                                                        {/* Placeholder أو الصورة */}
                                                        {!singleImage.length ? (
                                                            <div className="upload-placeholder">
                                                                <i><FontAwesomeIcon icon={faCloudUpload} /></i>
                                                                <p>{t('Click Here to upload Photo')}</p>
                                                            </div>
                                                        ) : (
                                                            <div className="image-preview single">
                                                                {singleImage.map((file, index) => (
                                                                    <img key={index} src={file.preview} alt={`upload preview ${index}`} className="img" />
                                                                ))}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>

                                            {/* Buttons */}
                                            <div className='group'>
                                                <button type='submit'>{t('Submit')}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                                {/* Security Section */}
                                <div className='points2 row align-items-center justify-content-end g-0'>
                                    <h3 className='title'>{t('Security')}</h3>
                                    <form onSubmit={handleSubmit} className='Security col-11 col-md-10 col-lg-10'>
                                        <div className='div1'>
                                            <label htmlFor='email'>{t('Email')}</label>
                                            <input
                                                type='text'
                                                id='email'
                                                name='email'
                                                value={email}
                                                onChange={(e) => { setEmail(e.target.value); handleChange(); }}
                                                readOnly
                                            />
                                        </div>
                                             <div className='div1'>
                                                <label htmlFor='password'>Current password</label>
                                                <input
                                                type='password'
                                                id='password'
                                                name='password'
                                                value={password}
                                                onChange={(e) => { setPassword(e.target.value); handleChange(); }}
                                                />
                                            </div>
                                            <div className='div1'>
                                                <label htmlFor='new_password'>New password</label>
                                                <input
                                                type='password'
                                                id='new_password'
                                                name='new password'
                                                value={new_password}
                                                onChange={(e) => { setNew_password(e.target.value); handleChange(); }}
                                                />
                                            </div>

                                            <div className='div1'>
                                                <label htmlFor='password_confirmation'>Password confirmation</label>
                                                <input
                                                type='password'
                                                id='password_confirmation'
                                                name='password confirmation'
                                                value={password_confirmation}
                                                onChange={(e) => { setPassword_confirmation(e.target.value); handleChange(); }}
                                                />
                                            </div>

                                            {/* عرض رسالة الخطأ إن وجدت */}
                                            {error && <div className="popup-error">{error}</div>}

                                            <div className='group'>
                                                <p
                                                type='submit'
                                                className={`Change ${isValid ? 'Valid' : ''}`}
                                                onClick={Change_Password}
                                                >
                                                Change Password
                                                </p>
                                                <p className='LogUt' onClick={handleIconClick}>
                                                {t('Log Out')}
                                            </p>
                                            <p className='Delete'>{t('Delete Account')}</p>
                                        </div>
                                    </form>
                                </div>

                                {/* Payment Methods Section */}
                                <div className='Payment row align-items-center justify-content-around g-0'>
                                    <div>
                                        <h3 className='title'>{t('Payment Methods')}</h3>
                                        <p>{t('Add New Card')}</p>
                                    </div>
                                    <div>
                                        <img src={img6} />
                                        <p>{t('Your Payment Method is empty')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {visibleSection === 4 && (
                    <div className='col Request Orders'>
                    <h3 className='title'>{t('Returns & Exchanges')}</h3>
                    {show_Returns_Exchanges === false &&
                    <>
                        <div className='container'>
                            <div className='row m-auto g-0'>
                                <div className='col-11 col-md-10 col-lg-8 m-auto'>
                                    <p className='Returns' onClick={Returns_Exchanges}>{t('Request Returns & Exchanges')}</p>
                                    {get_user_returns === null && <p className='History'>{t('You Have No Returns History')}</p>}
                                    {get_user_exchanges === null && <p className='History'>{t('You Have No Exchanges History')}</p>}
                                </div>
                            </div>
                        </div>

                        <div className='col'>
                            <div className='container'>
                                <div className='points2 row align-items-center justify-content-end g-0'>
                                    <div className='col-11 col-md-10 col-lg-10'>
                                        {get_user_returns?.data.data &&
                                        <>
                                            <p className='font-size2'>Returns orders</p>
                                            {get_user_returns?.data.data.map((order, index) => (
                                                <>
                                                    <div className='group_data'>
                                                        <div>
                                                            <p>{t('Order')}</p>
                                                            <p><span>{order.order?.order_code}</span></p>
                                                        </div>
                                                        <div>
                                                            <p>{t('Date')}</p>
                                                            <p>{order.order?.order_date}</p>
                                                        </div>
                                                        <div>
                                                            <p>{t('Status')}</p>
                                                            <p>{order.order?.order_status}</p>
                                                        </div>
                                                        <div>
                                                            <p>{t('Total')}</p>
                                                            <p><span>{order.order?.total_price} KWD</span> {t('For')} <span>{order.order?.num_of_items} {t('Item')}</span></p>
                                                        </div>
                                                    </div>
                
                                                    <div className='Actions'>
                                                        <p>{t('Actions')}</p>
                                                        <p className={visibleOrderId === order.order?.id ? 'btn' : 'btn_active'} onClick={() => toggleOrderVisibility(order.order?.id)}>{t('View')}</p>
                                                    </div>
                
                                                    {visibleOrderId === order.order?.id && (
                                                        <div className='container_Purchasing container'>
                                                            {order.order?.order_items.map((items) => (
                                                                <div className='container_prodects row justify-content-between' key={items?.id}>
                                                                    <div className='col-12 col-md-6 text-center'>
                                                                        <img src={items?.product_image} alt={items?.nike} />
                                                                    </div>
                                                                    <div className='details col-12 col-md-6'>
                                                                        <p>{items?.product}</p>
                                                                        <p>{t('Color')}: {items?.product_color}</p>
                                                                        <p>{t('Size')}: {items?.product_size}</p>
                                                                    </div>
                                                                    <div className='box_counter col-12 mt-3 text-center'>
                                                                        <div className="counter">
                                                                            <span className='span1' onClick={decrement}>-</span>
                                                                            <span className='span3'>{items?.quantity}</span>
                                                                            <span className='span2' onClick={increment}>+</span>
                                                                        </div>
                                                                        <i><FontAwesomeIcon icon={faTrash} /></i>
                                                                        <p><span>KWD</span> {items?.product_price}</p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                
                                                    <div className='Vouchers container'>
                                                        <div className='row justify-content-end'>
                                                            <div className='Quantity col-12'>
                                                                <div>
                                                                    <p>{t('Total Quantity')}</p>
                                                                    <p className='p2'>{order.order?.total_quantity}</p>
                                                                    <p>{t('Total Price')}</p>
                                                                    <p className='p3'><span>KWD </span>{order.order?.total_price}</p>
                                                                </div>
                                                                <div className='Promos'>
                                                                    <span>{t('Promos & Vouchers')}</span>
                                                                    <span>ASHTIRI {order.order?.order_id}</span>
                                                                </div>
                                                                <div className='Promos2'>
                                                                    <span>{t('Discount Voucher')}</span>
                                                                    <span className='Discount'>-<span className='KWD'>KWD</span> {order.order?.total_discount}</span>
                                                                </div>
                                                                <div className='Promos2'>
                                                                    <span>{t('Shipping Fee')}</span>
                                                                    <span><span className='KWD'>KWD</span> {order.order?.shipping_cost}</span>
                                                                </div>
                                                                <div className='Promos'>
                                                                    <span>{t('Total Price')}</span>
                                                                    <span><span className='KWD border-0'>KWD </span>{order.order?.total_price}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                
                                                    <div className="timelineI">
                                                        <div className="containe"><i className="icon">🔍</i></div>
                                                        <div className="containe"><i className="icon">📅</i></div>
                                                        <div className="containe"><i className="icon">🎯</i></div>
                                                        <div className="containe"><i className="icon">🎯</i></div>
                                                        <div className="containe"><i className="icon">🚀</i></div>
                                                    </div>
                                                    <div className="timeline">
                                                        <div className="containe"><span className="circle"></span></div>
                                                        <span className="line"></span>
                                                        <div className="containe"><span className="circle"></span></div>
                                                        <span className="line"></span>
                                                        <div className="containe"><span className="circle"></span></div>
                                                        <span className="line"></span>
                                                        <div className="containe"><span className="circle"></span></div>
                                                        <span className="line"></span>
                                                        <div className="containe"><span className="circle"></span></div>
                                                    </div>
                
                                                    <div className="timelineP">
                                                        <div className="containe"><p>{t('Order Received')}</p></div>
                                                        <div className="containe"><p>{t('On the way to get the order')}</p></div>
                                                        <div className="containe"><p>{t('Received & inspected')}</p></div>
                                                        <div className="containe"><p>{t('The new order has been shipped')}</p></div>
                                                        <div className="containe"><p>{t('Delivered')}</p></div>
                                                    </div>
                
                                                    <div className='mt-3'>
                                                        <p>- {t('Your return request has been received.')}</p>
                                                        <p>- {t('The representative is on his way to receive the product.')}</p>
                                                        <p>- {t('The new order has been shipped.')}</p>
                                                        <p>- {t('Your order has been successfully delivered.')}</p>
                                                    </div>
                                                </>
                                            ))}
                                        </>
                                        }
                                    </div>

                                    <div className='col-11 col-md-10 col-lg-10'>
                                        {get_user_exchanges?.data.data &&
                                        <>
                                            <p className='font-size2'>Exchanges orders</p>
                                            {get_user_exchanges?.data.data.map((order, index) => (
                                                <>
                                                    <div className='group_data'>
                                                        <div>
                                                            <p>{t('Order')}</p>
                                                            <p><span>{order.order?.order_code}</span></p>
                                                        </div>
                                                        <div>
                                                            <p>{t('Date')}</p>
                                                            <p>{order.order?.order_date}</p>
                                                        </div>
                                                        <div>
                                                            <p>{t('Status')}</p>
                                                            <p>{order.order?.order_status}</p>
                                                        </div>
                                                        <div>
                                                            <p>{t('Total')}</p>
                                                            <p><span>{order.order?.total_price} KWD</span> {t('For')} <span>{order.order?.num_of_items} {t('Item')}</span></p>
                                                        </div>
                                                    </div>
                
                                                    <div className='Actions'>
                                                        <p>{t('Actions')}</p>
                                                        <p className={visibleOrderId === order.order?.id ? 'btn' : 'btn_active'} onClick={() => toggleOrderVisibility(order.order?.id)}>{t('View')}</p>
                                                    </div>
                
                                                    {visibleOrderId === order.order?.id && (
                                                        <div className='container_Purchasing container'>
                                                            {order.order?.order_items.map((items) => (
                                                                <div className='container_prodects row justify-content-between' key={items?.id}>
                                                                    <div className='col-12 col-md-6 text-center'>
                                                                        <img src={items?.product_image} alt={items?.nike} />
                                                                    </div>
                                                                    <div className='details col-12 col-md-6'>
                                                                        <p>{items?.product}</p>
                                                                        <p>{t('Color')}: {items?.product_color}</p>
                                                                        <p>{t('Size')}: {items?.product_size}</p>
                                                                    </div>
                                                                    <div className='box_counter col-12 mt-3 text-center'>
                                                                        <div className="counter">
                                                                            <span className='span1' onClick={decrement}>-</span>
                                                                            <span className='span3'>{items?.quantity}</span>
                                                                            <span className='span2' onClick={increment}>+</span>
                                                                        </div>
                                                                        <i><FontAwesomeIcon icon={faTrash} /></i>
                                                                        <p><span>KWD</span> {items?.product_price}</p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}
                
                                                    <div className='Vouchers container'>
                                                        <div className='row justify-content-end'>
                                                            <div className='Quantity col-12'>
                                                                <div>
                                                                    <p>{t('Total Quantity')}</p>
                                                                    <p className='p2'>{order.order?.total_quantity}</p>
                                                                    <p>{t('Total Price')}</p>
                                                                    <p className='p3'><span>KWD </span>{order.order?.total_price}</p>
                                                                </div>
                                                                <div className='Promos'>
                                                                    <span>{t('Promos & Vouchers')}</span>
                                                                    <span>ASHTIRI {order.order?.order_id}</span>
                                                                </div>
                                                                <div className='Promos2'>
                                                                    <span>{t('Discount Voucher')}</span>
                                                                    <span className='Discount'>-<span className='KWD'>KWD</span> {order.order?.total_discount}</span>
                                                                </div>
                                                                <div className='Promos2'>
                                                                    <span>{t('Shipping Fee')}</span>
                                                                    <span><span className='KWD'>KWD</span> {order.order?.shipping_cost}</span>
                                                                </div>
                                                                <div className='Promos'>
                                                                    <span>{t('Total Price')}</span>
                                                                    <span><span className='KWD border-0'>KWD </span>{order.order?.total_price}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                
                                                    <div className="timelineI">
                                                        <div className="containe"><i className="icon">🔍</i></div>
                                                        <div className="containe"><i className="icon">📅</i></div>
                                                        <div className="containe"><i className="icon">🎯</i></div>
                                                        <div className="containe"><i className="icon">🎯</i></div>
                                                        <div className="containe"><i className="icon">🚀</i></div>
                                                    </div>
                                                    <div className="timeline">
                                                        <div className="containe"><span className="circle"></span></div>
                                                        <span className="line"></span>
                                                        <div className="containe"><span className="circle"></span></div>
                                                        <span className="line"></span>
                                                        <div className="containe"><span className="circle"></span></div>
                                                        <span className="line"></span>
                                                        <div className="containe"><span className="circle"></span></div>
                                                        <span className="line"></span>
                                                        <div className="containe"><span className="circle"></span></div>
                                                    </div>
                
                                                    <div className="timelineP">
                                                        <div className="containe"><p>{t('Order Received')}</p></div>
                                                        <div className="containe"><p>{t('On the way to get the order')}</p></div>
                                                        <div className="containe"><p>{t('Received & inspected')}</p></div>
                                                        <div className="containe"><p>{t('The new order has been shipped')}</p></div>
                                                        <div className="containe"><p>{t('Delivered')}</p></div>
                                                    </div>
                
                                                    <div className='mt-3'>
                                                        <p>- {t('Your return request has been received.')}</p>
                                                        <p>- {t('The representative is on his way to receive the product.')}</p>
                                                        <p>- {t('The new order has been shipped.')}</p>
                                                        <p>- {t('Your order has been successfully delivered.')}</p>
                                                    </div>
                                                </>
                                            ))}
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                    }



                    {show_Returns_Exchanges === true &&
                        <>
                            <div className='container'>
                                <div className='row m-auto g-0'>
                                    <div className='col-11 col-md-10 col-lg-8 m-auto'>
                                        <p className='cansl' onClick={Returns_Exchanges}>{t('Cancel Returns & Exchanges')}</p>
                                    </div>
                                </div>
                            </div>

                            <div className='col'>
                                <div className='container'>
                                    <div className='points2 row align-items-center justify-content-end g-0'>
                                        <div className='col-11 col-md-10 col-lg-10'>
                                            <div className='Order_Number'>
                                                <p>{t('Order Number')}</p>
                                                <input type="text" value={orderNumber_Returns} onChange={handleInputChangeReturns} />
                                            </div>

                                            {myProfiles?.data?.data?.track_order && (
                                                <>
                                                    <div className='group_data'>
                                                        {loadingExchange && <Loader />}
                                                        {loadingReturn_orders && <Loader />}
                                                        <div>
                                                            <p>{t('Order')}</p>
                                                            <p><span>{myProfiles?.data?.data?.track_order?.order_code}</span></p>
                                                        </div>
                                                        <div>
                                                            <p>{t('Date')}</p>
                                                            <p>{myProfiles?.data?.data?.track_order?.order_date}</p>
                                                        </div>
                                                        <div>
                                                            <p>{t('Status')}</p>
                                                            <p>{myProfiles?.data?.data?.track_order?.order_status}</p>
                                                        </div>
                                                        <div>
                                                            <p>{t('Total')}</p>
                                                            <p><span>{myProfiles?.data?.data?.track_order?.total_price} KWD</span> {t('For')} <span>{myProfiles?.data?.data?.track_order?.num_of_items} {t('Item')}</span></p>
                                                        </div>
                                                    </div>

                                                    <div className='Actions'>
                                                        <p>{t('Actions')}</p>
                                                        <p className={visibleOrderId === myProfiles?.data?.data?.track_order?.id ? 'btn' : 'btn_active'} onClick={() => toggleOrderVisibility(myProfiles?.data?.data?.track_order?.id)}>{t('View')}</p>
                                                    </div>

                                                    {visibleOrderId === myProfiles?.data?.data?.track_order?.id && (
                                                        <div className='container_Purchasing container'>
                                                            {myProfiles?.data?.data?.track_order?.order_items.map((items) => (
                                                                <div className='container_prodects row justify-content-between' key={items?.id}>
                                                                    <div className='col-12 col-md-6 text-center'>
                                                                        <img src={items?.product_image} alt={items?.nike} />
                                                                    </div>
                                                                    <div className='details col-12 col-md-6'>
                                                                        <p>{items?.product}</p>
                                                                        <p>{t('Color')}: {items?.product_color}</p>
                                                                        <p>{t('Size')}: {items?.product_size}</p>
                                                                    </div>
                                                                    <div className='box_counter col-12 mt-3 text-center'>
                                                                        <div className="counter">
                                                                            <span className='span1' onClick={decrement}>-</span>
                                                                            <span className='span3'>{items?.quantity}</span>
                                                                            <span className='span2' onClick={increment}>+</span>
                                                                        </div>
                                                                        <i><FontAwesomeIcon icon={faTrash} /></i>
                                                                        <p><span>KWD</span> {items?.product_price}</p>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    )}

                                                    <div className='Vouchers container'>
                                                        <div className='row justify-content-end'>
                                                            <div className='Quantity col-12'>
                                                                <div>
                                                                    <p>{t('Total Quantity')}</p>
                                                                    <p className='p2'>{myProfiles?.data?.data?.track_order?.total_quantity}</p>
                                                                    <p>{t('Total Price')}</p>
                                                                    <p className='p3'><span>KWD </span>{myProfiles?.data?.data?.track_order?.total_price}</p>
                                                                </div>
                                                                <div className='Promos'>
                                                                    <span>{t('Promos & Vouchers')}</span>
                                                                    <span>ASHTIRI {myProfiles?.data?.data?.track_order?.order_id}</span>
                                                                </div>
                                                                <div className='Promos2'>
                                                                    <span>{t('Discount Voucher')}</span>
                                                                    <span className='Discount'>-<span className='KWD'>KWD</span> {myProfiles?.data?.data?.track_order?.total_discount}</span>
                                                                </div>
                                                                <div className='Promos2'>
                                                                    <span>{t('Shipping Fee')}</span>
                                                                    <span><span className='KWD'>KWD</span> {myProfiles?.data?.data?.track_order?.shipping_cost}</span>
                                                                </div>
                                                                <div className='Promos'>
                                                                    <span>{t('Total Price')}</span>
                                                                    <span><span className='KWD border-0'>KWD </span>{myProfiles?.data?.data?.track_order?.total_price}</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <div className="timelineI">
                                                            <div className="containe"><i className="icon">🔍</i></div>
                                                            <div className="containe"><i className="icon">📅</i></div>
                                                            <div className="containe"><i className="icon">🎯</i></div>
                                                            <div className="containe"><i className="icon">🚀</i></div>
                                                        </div>
                                                        <div className="timeline">
                                                            <div className="containe"><span className="circle"></span></div>
                                                            <span className="line"></span>
                                                            <div className="containe"><span className="circle"></span></div>
                                                            <span className="line"></span>
                                                            <div className="containe"><span className="circle"></span></div>
                                                            <span className="line"></span>
                                                            <div className="containe"><span className="circle"></span></div>
                                                        </div>
                                                        <div className="timelineP">
                                                            <div className="containe"><p>{t('Order Received')}</p></div>
                                                            <div className="containe"><p>{t('On the way to get the order')}</p></div>
                                                            <div className="containe"><p>{t('Received & inspected')}</p></div>
                                                            <div className="containe"><p>{t('The new order has been shipped')}</p></div>
                                                            <div className="containe"><p>{t('Delivered')}</p></div>
                                                        </div>
                                                    </div>

                                                    <div className='mt-3'>
                                                        <p className='D_fouter'>- {t('Your return request has been received.')}</p>
                                                        <p className='D_fouter'>- {t('The representative is on his way to receive the product.')}</p>
                                                        <p className='D_fouter'>- {t('The new order has been shipped.')}</p>
                                                        <p className='D_fouter'>- {t('Your order has been successfully delivered.')}</p>
                                                    </div>

                                                    <div className='Returns_Exchange'>
                                                        <p className='Return' onClick={() => handelreturn_orders()}>{t('Returns')}</p>
                                                        <p className='Exchange' onClick={() => handelExchange()}>{t('Exchanges')}</p>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </>}
                    </div>
                    )}
                    {visibleSection === 3 && (
                        <div className='col Orders'>
                        <h3 className='title'>{t('Track Orders')}</h3>
                        <div className='col'>
                            <div className='container'>
                                <div className='points2 row align-items-center justify-content-end g-0'>
                                    <div className='col-11 col-md-10 col-lg-10'>
                                        <div className='Order_Number'>
                                            <p>{t('Order Number')}</p>
                                            <input type="text" value={orderNumber} onChange={handleInputChange} />
                                        </div>
                                        {myProfiles?.data?.data?.track_order &&
                                        <>
                                            <div className='group_data'>
                                                <div>
                                                    <p>{t('Order')}</p>
                                                    <p><span>{myProfiles?.data?.data?.track_order?.order_code}</span></p>
                                                </div>
                                                <div>
                                                    <p>{t('Date')}</p>
                                                    <p>{myProfiles?.data?.data?.track_order?.order_date}</p>
                                                </div>
                                                <div>
                                                    <p>{t('Status')}</p>
                                                    <p>{myProfiles?.data?.data?.track_order?.order_status}</p>
                                                </div>
                                                <div>
                                                    <p>{t('Total')}</p>
                                                    <p><span>{myProfiles?.data?.data?.track_order?.total_price} KWD</span> {t('For')} <span>{myProfiles?.data?.data?.track_order?.num_of_items} {t('Items')}</span></p>
                                                </div>
                                            </div>
                                            <div className='Actions'>
                                                <p>{t('Actions')}</p>
                                                <p className={visibleOrderId === myProfiles?.data?.data?.track_order?.id ? 'btn' : 'btn_active'} onClick={() => toggleOrderVisibility(myProfiles?.data?.data?.track_order?.id)}>{t('View')}</p>
                                            </div>
                                            {visibleOrderId === myProfiles?.data?.data?.track_order?.id && (
                                                <div className='container_Purchasing container'>
                                                    {myProfiles?.data?.data?.track_order?.order_items.map((items) => (
                                                        <div className='container_prodects row justify-content-between' key={items?.id}>
                                                            <div className='col-12 col-md-6 text-center'>
                                                                <img src={items?.product_image} alt={items?.nike} />
                                                            </div>
                                                            <div className='details col-12 col-md-6'>
                                                                <p>{items?.product}</p>
                                                                <p>{t('Color')}: {items?.product_color}</p>
                                                                <p>{t('Size')}: {items?.product_size}</p>
                                                            </div>
                                                            <div className='box_counter col-12 mt-3 text-center'>
                                                                <div className="counter">
                                                                    <span className='span1' onClick={decrement}>-</span>
                                                                    <span className='span3'>{items?.quantity}</span>
                                                                    <span className='span2' onClick={increment}>+</span>
                                                                </div>
                                                                <i><FontAwesomeIcon icon={faTrash} /></i>
                                                                <p><span>KWD</span> {items?.product_price}</p>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            )}
                                            <div className='Vouchers container'>
                                                <div className='row justify-content-end'>
                                                    <div className='Quantity col-12'>
                                                        <div>
                                                            <p>{t('Total Quantity')}</p>
                                                            <p className='p2'>{myProfiles?.data?.data?.track_order?.total_quantity}</p>
                                                            <p>{t('Total Price')}</p>
                                                            <p className='p3'><span>KWD </span>{myProfiles?.data?.data?.track_order?.total_price}</p>
                                                        </div>
                                                        <div className='Promos'>
                                                            <span>{t('Promos & Vouchers')}</span>
                                                            <span>ASHTIRI {myProfiles?.data?.data?.track_order?.order_id}</span>
                                                        </div>
                                                        <div className='Promos2'>
                                                            <span>{t('Discount Voucher')}</span>
                                                            <span className='Discount'>-<span className='KWD'>KWD</span> {myProfiles?.data?.data?.track_order?.total_discount}</span>
                                                        </div>
                                                        <div className='Promos2'>
                                                            <span>{t('Shipping Fee')}</span>
                                                            <span><span className='KWD'>KWD</span> {myProfiles?.data?.data?.track_order?.shipping_cost}</span>
                                                        </div>
                                                        <div className='Promos'>
                                                            <span>{t('Total Price')}</span>
                                                            <span><span className='KWD border-0'>KWD </span>{myProfiles?.data?.data?.track_order?.total_price}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div class="timelineI">
                                                    <div class="containe">
                                                        <i class="icon">🔍</i>
                                                    </div>
                                                    <div class="containe">
                                                        <i class="icon">📅</i>
                                                    </div>
                                                    <div class="containe">
                                                        <i class="icon">🎯</i>
                                                    </div>
                                                    <div class="containe">
                                                        <i class="icon">🚀</i>
                                                    </div>
                                                </div>
                                                <div class="timeline">
                                                    <div class="containe">
                                                        <span class="circle"></span>
                                                    </div>
                                                    <span class="line"></span>
                                                    <div class="containe">
                                                        <span class="circle"></span>
                                                    </div>
                                                    <span class="line"></span>
                                                    <div class="containe">
                                                        <span class="circle"></span>
                                                    </div>
                                                    <span class="line"></span>
                                                    <div class="containe">
                                                        <span class="circle"></span>
                                                    </div>
                                                </div>

                                                <div class="timelineP">
                                                    <div class="containe">
                                                        <p>{t('Order Received')}</p>
                                                    </div>
                                                    <div class="containe">
                                                        <p>{t('In Progress')}</p>
                                                    </div>
                                                    <div class="containe">
                                                        <p>{t('On The Way')}</p>
                                                    </div>
                                                    <div class="containe">
                                                        <p>{t('Delivered')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='mt-3'>
                                                <p>- {t('Your request has been received successfully.')}</p>
                                                <p>- {t('Your request is in progress.')}</p>
                                                <p>- {t('The order has been shipped. It should arrive within 24 hours.')}</p>
                                                <p>- {t('Your order has been successfully delivered.')}</p>
                                            </div>
                                        </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                    
                    )}

                </div>
            </div>
            <Footer />
        </div>
        </>
    );
}

export default Profile;