import './Footer.css';
import icon11 from '../Icons/Icon-11.png'
import icon12 from '../Icons/Icon-12.png'
import icon13 from '../Icons/Icon-13.png'
import icon14 from '../Icons/Icon-14.png'
import icon15 from '../Icons/Icon-15.png'
import icon16 from '../Icons/Icon-16.png'
import icon17 from '../Icons/Google-play-icon-illustration-premium-vector-PNG.png'
import icon18 from '../Icons/app-store.svg'
import { useTranslation } from "react-i18next";
import { useState, useEffect} from 'react';
function Footer() {
  const { t } = useTranslation();

  return (
    <div className={`Footer`}>
      <div className='hr'></div>

      <div className={`fooater container-fluid`}>
      <div className='row align-items-center justify-content-between'>
        <div className="div1 col-12 sm-6 col-md-5 col-lg-4">
          <p>{t('About Us')}</p>
          <p>{t('Careers')}</p>
          <p>{t("Contact US")}</p>
          <p>{t('Stores')}</p>
        </div>
        <div className="div2 col Sm-6 col-md-5 col-lg-4">
          <div>
            <span className="span1">E-mail</span>
            <span className="span2">Sign Up</span>
          </div>
          <div>
            <div>
              <i><img src={icon18}/></i>
              <p>
                <span>Download on the</span>
                <h3>App Store</h3>
              </p>
            </div>
            <div>
            <i><img src={icon17}/></i>
              
              <p>
                <span>GET IN ON</span>
                <h3>Google Play</h3>
              </p>
            </div>
          </div>
        </div>
        <div className="div3 col-12 sm-12 col-md-12 col-lg-4">
          <a href='https://dashcontrol.ashtiri.com/' className='text-decoration-none'>
          <p className='h col-12'>{t('SELL ON ASHTIRI')}</p>
          </a>
          <p>{t('follow Us On')}</p>
          <div>
            <img src={icon11}/>
            <img src={icon12}/>
            <img src={icon13}/>
            <img src={icon14}/>
            <img src={icon15}/>
            <img src={icon16}/>
           
          </div>
        </div>
      </div>
      </div>
      <div className="copy">Copyright &copy; 2024 Ashtiri</div>
    </div>
  );
}

export default Footer;