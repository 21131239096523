import './Register.css';
import group from "../Icons/Group.png";
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { addUser, login, verification, loginFirebase } from '../Redux/API';
import { sendReset, resetPassword, verifyReset } from '../Redux/API';
import { faCartPlus, faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GoogleOAuthProvider, useGoogleLogin } from '@react-oauth/google';
import google from '../Icons/android_light_rd_na@1x.png'
import { useTranslation } from 'react-i18next';
import facebook from '../Icons/facebook-svgrepo-com.svg';
import google2 from '../Icons/google-color-svgrepo-com.svg';
import instagram from '../Icons/instagram-2-1-logo-svgrepo-com.svg';
import apple from '../Icons/apple-svgrepo-com.svg';


import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import Loader from '../Loader';

// import Loader from '../Loader';

function Register() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const navigate = useNavigate();

    const errorRegister = useSelector(state => state.Data.errorAddUser);
    const messageOTP = useSelector(state => state.Data.errorVerification);
    const errorLogin =useSelector(state => state.Data.errorLogin);


    const userData = useSelector(state => state.Data.userData);
    const logins = useSelector(state => state.Data.login);

    const loadingAddUser = useSelector(state => state.Data.loadingAddUser);
    const loadingVerification = useSelector(state => state.Data.loadingVerification);
    const loadingLogin = useSelector(state => state.Data.loadingLogin);


    // هنستخدم useEffect عشان نحفظ البيانات في localStorage
    useEffect(() => {
        if (userData || logins) {
            const userInfo = userData ? userData : logins;
            window.localStorage.setItem('UserInfo', JSON.stringify(userInfo));
            console.log('User info saved to localStorage:', userInfo);
        }
    }, [userData, logins]);

    // هنجيب البيانات المحفوظة من الـ localStorage
    const [getLocalStorage, setGetLocalStorage] = useState(() => {
        const savedData = window.localStorage.getItem('UserInfo');
        return savedData ? JSON.parse(savedData) : null;
    });

    // لو فيه بيانات في localStorage هنعمل استعراض للإيميل والتوكن
    const [email, setEmail] = useState( getLocalStorage && getLocalStorage?.data && getLocalStorage?.data?.data?.user ? getLocalStorage?.data?.data?.user?.email : null);

    useEffect(() => {
        if (userData) {
            setShowToken(true);
        }
    }, [userData]);

    const isValidEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const isValid = emailRegex.test(email);
        console.log(`Email validation for "${email}":`, isValid);
        return isValid;
    };

    const [activeButton, setActiveButton] = useState('signup');

    const [signupEmail, setSignupEmail] = useState('');
    const [signupPassword, setSignupPassword] = useState('');
    const [signupError, setSignupError] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [role, setRole] = useState('customer');

    const [loginEmail, setLoginEmail] = useState('');
    const [loginPassword, setLoginPassword] = useState('');
    const [loginError, setLoginError] = useState('');

    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [isFormValid, setIsFormValid] = useState(false);
    const [showToken, setShowToken] = useState(false);

    const [showOTP, setShowOTP] = useState(false);

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    
        // إعادة تعيين الحقول غير النشطة عند التبديل بين الزرين
        if (buttonName === 'signup') {
            setLoginEmail('');
            setLoginPassword('');
            setLoginError('');
        } else if (buttonName === 'login') {
            setSignupEmail('');
            setSignupPassword('');
            setSignupError('');
        }
    };

    useEffect(() => {
        if (activeButton === 'signup') {
            const isValid = signupEmail && signupPassword && firstName && lastName && isValidEmail(signupEmail) && isValidPassword(signupPassword);
            setIsFormValid(isValid);
        } else if (activeButton === 'login') {
            const isValid = loginEmail && loginPassword && isValidEmail(loginEmail);
            setIsFormValid(isValid);
        }
    }, [signupEmail, signupPassword, loginEmail, loginPassword, activeButton, firstName, lastName]);


    const isValidPasswordsignup = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        return passwordRegex.test(password);
    };
    
    const handleSubmit = (e) => {
        e.preventDefault(); // منع إعادة تحميل الصفحة
    
        if (!isFormValid) {
            if (activeButton === 'login') {
                setLoginError(t('fillFieldsWithValidInfo')); // ترجمة الرسالة
            } else if (activeButton === 'signup') {
                setSignupError(t('fillFieldsWithValidInfo')); // ترجمة الرسالة
            }
        } else if (activeButton === 'signup' && !isValidPasswordsignup(signupPassword)) {
            setSignupError(t('passwordRequirements')); // ترجمة الرسالة
        } else {
            setLoginError('');
            setSignupError('');
            if (activeButton === 'signup') {
                dispatch(addUser({
                    first_name: firstName,
                    last_name: lastName,
                    password: signupPassword,
                    email: signupEmail,
                    role
                }))
                .unwrap()
                .then((response) => {
                    if (response.status === 200) {
                        setShowToken(true);
                        const savedData = window.localStorage.getItem('UserInfo');
                        setEmail(savedData ? JSON.parse(savedData).data?.data?.user?.email : null);
                    } else {
                        setSignupError(t('registrationFailed')); // ترجمة الرسالة
                    }
                })
                .catch((error) => {
                    console.error('Error during registration:', error);
                    setSignupError(error.message || t('generalError')); // ترجمة الرسالة
                });
            } else if (activeButton === 'login') {
                dispatch(login({
                    email: loginEmail,
                    password: loginPassword
                }))
                .unwrap()
                .then((response) => {
                    if (response.status === 200 || 2001) {
                        // تسجيل الدخول بنجاح
                        console.log(t('loginSuccessful')); // ترجمة الرسالة
                        window.location.reload()
                    } else {
                        // setLoginError(t('loginFailed')); // ترجمة الرسالة
                    }
                })
                .catch((error) => {
                    console.error('Error during login:', error);
                    setLoginError(error);
                });
            }
        }
    };

    const handleOTPSubmit = () => {
        const formattedOtp = otp.replace(/\s/g, '');
        console.log(formattedOtp);
        console.log('Formatted OTP:', formattedOtp);
    
        if (formattedOtp.length === 5 && !isNaN(formattedOtp)) {
            setError('');
            dispatch(verification({ email: email, verification_code: formattedOtp }))
                .then((action) => {
                    if (action.payload && action.meta.requestStatus === 'fulfilled') {
                        console.log('Verification successful!');
                        window.location.reload(); // عمل إعادة تحميل للصفحة
                    }
                });
        } else {
            console.log('Invalid OTP:', formattedOtp);
            setError('OTP must be exactly 5 digits and must be a number.');
        }
    };


    const handleOtpChange = (e) => {
        const value = e.target.value.replace(/\s/g, '');
        if (/^\d*$/.test(value) && value.length <= 5) {
            setOtp(value.split('').join(' '));
        }
    };

    const handleIconClick = () => {
        console.log('Icon clicked, resetting all containers and clearing form values');
        
        window.localStorage.removeItem('UserInfo');
        window.localStorage.removeItem('userEmail');
    
        // إعادة تعيين جميع الحقول إلى قيم فارغة
        setSignupEmail('');
        setSignupPassword('');
        setFirstName('');
        setLastName('');
        setLoginEmail('');
        setLoginPassword('');
        setOtp('');
        setOtpReser('');
        setEmailValid('');
        setPassword('');
        setConfirmPassword('');
        setError('');
        setErrorReset('');
        setErrorResetPassword('');
        setLoginError('');
        setSignupError('');
        setLoginErrorReset('');
    
        // إعادة تعيين الأزرار والحالة
        // setActiveButton('signup'); // خليك في `signup container`
        setActiveButton(null); 
        setShowOTP(false);
        setShowToken(false);
        setShowEmail(false);
        setShowOtpContainer(false);
        setShowPasswordContainer(false);
    };
    

    // ForgotPassword
    const sendResetData = useSelector(state => state.Data.sendReset);
    const loadingSendReset = useSelector(state => state.Data.loadingSendReset);
    const errorSendReset = useSelector(state => state.Data.errorSendReset);
    
    const verifyResetData = useSelector(state => state.Data.verifyReset);
    const loadingVerifyReset = useSelector(state => state.Data.loadingVerifyReset);
    const errorVerifyReset = useSelector(state => state.Data.errorVerifyReset);

    const resetPasswordData = useSelector(state => state.Data.resetPassword);
    const loadingresetPassword = useSelector(state => state.Data.loadingresetPassword);
    const errorresetPassword = useSelector(state => state.Data.errorresetPassword);


    const isValidEmailReset = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const [emailValid, setEmailValid] = useState('');
    const [errorResetPassword, setErrorResetPassword] = useState('');
    const [loginErrorReset, setLoginErrorReset] = useState('');
    const [otpReset, setOtpReser] = useState('');
    const [errorReset, setErrorReset] = useState('');
    const [isFormValidEmail, setIsFormValidEmail] = useState(false);
    const [isPasswordValid, setPasswordValid] = useState(false);

    const [Password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showOtpContainer, setShowOtpContainer] = useState(false);
    const [showPasswordContainer, setShowPasswordContainer] = useState(false);
    const [showEmail, setShowEmail] = useState(false);

    useEffect(() => {
        const isValid = isValidEmailReset(emailValid);
        setIsFormValidEmail(isValid);
    }, [emailValid]);

    const handleSubmitEmail = () => {
        if (!isFormValidEmail) {
            setLoginErrorReset('Please fill in all fields with valid information.');
        } else {
            // حفظ البريد الإلكتروني في localStorage
            window.localStorage.setItem('userEmail', emailValid);

            dispatch(sendReset({email: emailValid }))
            .unwrap()
            .then((response) => {
                if (response.status === 200) {
                    setShowOtpContainer(true);
                    setShowEmail(false)
                }
            })
            .catch((error) => {

            });
        }
    };




    const isValidPassword = (password) => {
        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,}$/;
        return passwordRegex.test(password);
    };

    const [showPopup, setShowPopup] = useState(false);

    const handleSubmitPassword = () => {
        if (!Password || !confirmPassword) {
            setErrorResetPassword('يرجى إدخال كلمة المرور وتأكيدها.');
        } else if (!isValidPassword(Password)) {
            setErrorResetPassword('يجب أن تحتوي كلمة المرور على حروف كبيرة وصغيرة وأرقام.');
        } else if (Password !== confirmPassword) {
            setErrorResetPassword('كلمات المرور غير متطابقة.');
        } else {
            setErrorResetPassword('');
            setPasswordValid(true);
            const storedEmail = window.localStorage.getItem('userEmail');
            dispatch(resetPassword({ email: storedEmail, password: Password, password_confirmation: confirmPassword }))
                .unwrap()
                .then((response) => {
                    if (response.status === 200) {
                        setShowPasswordContainer(false);
                        setActiveButton('login');
                        setShowPopup(true); // عرض البوب أب
                        setTimeout(() => setShowPopup(false), 4000); // إخفاء البوب أب بعد 3 ثواني
                    }
                })
                .catch((error) => {
           
                });
        }
    };

    useEffect(() => {
        if (Password && confirmPassword) {
            setPasswordValid(isValidPassword(Password) && Password === confirmPassword);
        } else {
            setPasswordValid(false);
        }
    }, [Password, confirmPassword]);

    

    const handleOTPSubmitReset = () => {
        const formattedOtp = otpReset.replace(/\s/g, '');
        if (formattedOtp.length === 5 && !isNaN(formattedOtp)) {
            setErrorReset('');
            // استخدام البريد الإلكتروني من localStorage عند إرسال OTP
            const storedEmail = window.localStorage.getItem('userEmail');
            dispatch(verifyReset({ email: storedEmail, verification_code: formattedOtp }))
            .unwrap()
            .then((response) => {
                if (response.status === 200) {
                    setShowPasswordContainer(true);
                    setShowOtpContainer(false)
                    setShowEmail(false)
                }
            })
            .catch((error) => {
            
            });
        } else {
            setErrorReset('OTP must be exactly 5 digits and must be a number.');
        }
    };

    const [isOtpValid, setIsOtpValid] = useState(false);
    useEffect(() => {
        // تحقق من أن الـ OTP يتكون من 5 أرقام
        const formattedOtp = otpReset.replace(/\s/g, '');
        setIsOtpValid(formattedOtp.length === 5 && !isNaN(formattedOtp));
    }, [otpReset]);

    const handleOtpChangeReset = (e) => {
        const value = e.target.value.replace(/\s/g, '');
        if (/^\d*$/.test(value) && value.length <= 5) {
            setOtpReser(value.split('').join(' '));
        }
    };

    const showReset = () => {
        setShowEmail(true)
        setActiveButton(null)
    };

    const CustomGoogleLoginButton = () => {
        const login = useGoogleLogin({
          onSuccess: credentialResponse => {
            console.log(credentialResponse);
            // أضف هنا الكود لمعالجة الاستجابة بعد تسجيل الدخول بنجاح
          },
          onError: () => {
            console.log('Login Failed');
            // أضف هنا الكود لمعالجة الخطأ إذا فشل تسجيل الدخول
          }
        });
      
        return (
          <div className='div8'
            onClick={login}
          >
            <img
              src={google2}
              alt="Google icon"
              style={{ width: "25px", marginRight: "13px" }}
            />
      {t('loginWithGoogle')} 
      </div>
        );
      };
      
    
// زر تسجيل الدخول باستخدام فيسبوك
const CustomFacebookLoginButton = () => {
    const responseFacebook = (response) => {
      console.log(response);
      if (response.status !== 'unknown') {
        // أضف هنا الكود لمعالجة الاستجابة بعد تسجيل الدخول بنجاح
      } else {
        console.log('Login Failed');
        // أضف هنا الكود لمعالجة الخطأ إذا فشل تسجيل الدخول
      }
    };
  
    return (
      <FacebookLogin
        appId="YOUR_APP_ID" // استبدل YOUR_APP_ID بمعرف التطبيق الخاص بك من فيسبوك
        autoLoad={false}
        fields="name,email,picture"
        callback={responseFacebook}
        render={renderProps => (
          <div className='div9' onClick={renderProps.onClick}>
            <img
              src={facebook}
              alt="Facebook icon"
              style={{ width: "25px", marginRight: "13px" }}
            />
            {t('loginWithFacebook')}
          </div>
        )}
      />
    );
  };


    return (
        <div className="Register">
            <div className='signup container px-3 px-md-0'>
            {!showToken &&
      <>
        {activeButton === 'signup' &&
          <div className='row'>
            <div className="cont col-12 col-sm-10 col-xl-6 col-md-6 col-lg-5 col-xl-4 col-xxl-4 mx-auto g-0">
              {loadingAddUser && <Loader />}
              <div className='icon'>
                <i className='font-size2' onClick={handleIconClick}><FontAwesomeIcon icon={faTimes} /></i>
              </div>
              <div className='logo'>
                <img src={group} alt="group" />
                <p>{t('welcome')}</p>
              </div>
              <div className='div1'>
                <p>{t('createAccount')}</p>
              </div>
              <div className='div2'>
                <p
                  className={activeButton === 'login' ? 'active' : ''}
                  onClick={() => handleButtonClick('login')}
                >
                  {t('login')}
                </p>
                <p
                  className={activeButton === 'signup' ? 'active' : ''}
                  onClick={() => handleButtonClick('signup')}
                >
                  {t('signup')}
                </p>
              </div>
                <form onSubmit={handleSubmit}>

                    <div className='div3'>
                        <div>
                        <input
                            placeholder={t('enterEmail')}
                            onChange={(e) => setSignupEmail(e.target.value)}
                        />
                        </div>
                    </div>
                    <div className='div4'>
                        <div>
                        <input
                            placeholder={t('password')}
                            type="password"
                            onChange={(e) => setSignupPassword(e.target.value)}
                        />
                        </div>
                    </div>
                    <div className='div4'>
                        <div>
                        <input
                            placeholder={t('firstName')}
                            type="text"
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                        </div>
                    </div>
                    <div className='div4'>
                        <div>
                        <input
                            placeholder={t('lastName')}
                            type="text"
                            onChange={(e) => setLastName(e.target.value)}
                        />
                        </div>
                    </div>
                    <div className='div_lest'>
                        <p className='font-size1'>{t('eightCharsMin')}</p>
                        <p className='font-size1'>{t('oneLetter')}</p>
                        <p className='font-size1'>{t('oneNumber')}</p>
                    </div>
                    {activeButton === 'signup' && signupError && <div className='error-message'>{signupError}</div>}
                    {errorRegister?.message && <div className='error-message'>{errorRegister?.message}</div>}
                    <div className='div6'>
                        <div>
                        <button
                            className={isFormValid ? 'continue-button valid' : 'continue-button'}
                            type="submit"
                        >
                            {t('CONTINUE')}
                        </button>
                        </div>
                    </div>
                </form>
              <div className='div7'>
                <p>{t('orLoginWithSocial')}</p>
              </div>
              <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID" className='custom-google-button'>
                <CustomGoogleLoginButton />
              </GoogleOAuthProvider>
              <CustomFacebookLoginButton />
              <div className='div10'>
                <i>icon</i>
                {t('loginInstagram')}
              </div>
              <div className='div11'>
                <i>icon</i>
                {t('loginApple')}
              </div>
            </div>
          </div>
        }
      </>
    }
            </div>
            {showToken && (
                <div className='container'>
                    <div className='row'>
                        <div className="cont col-12 col-sm-10 col-xl-6 col-md-6 col-lg-5 col-xl-4 col-xxl-4 mx-auto g-0">
                            {loadingVerification && <Loader />}
                            <div className='icon'>
                                <i className='font-size2' onClick={handleIconClick}><FontAwesomeIcon icon={faTimes}/></i>
                            </div>
                            <div className='logo'>
                                <img src={group} alt="group" />
                                <p>{t('Welcome to the new Experience')}</p>
                            </div>
                            <div className='div1'>
                                <p>{t('VERIFY YOUR EMAIL ADDRESS')}</p>
                            </div>
                            <div className='div13'>
                                <p className='font-size1'>{t('Verify your email using the OTP sent to your email address')}</p>
                            </div>
                            <div className='div14'>
                                <p>{t('Resend OTP')}</p>
                            </div>

                            {error && <div className='error-message'>{error}</div>}
                            <div className='error-message'>{messageOTP?.message}</div>
                            <div className='div15'>
                                <p>{t('OTP')}</p>
                                <input
                                    className="otp-input"
                                    value={otp}
                                    onChange={handleOtpChange}
                                    placeholder="0 0 0 0 0"
                                    maxLength="9" // Maximum length including spaces
                                />
                            </div>
                            
                            <div className='div6'>
                                <div>
                                    <p
                                        className='continue-button valid'
                                        onClick={handleOTPSubmit}
                                    >
                                        {t('CONTINUE')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div className='login container'>
                {activeButton === 'login' && (
                    <div className='row'>
                        <div className="cont col-12 col-sm-10  col-xl-6 col-md-6 col-lg-5 col-xl-4 col-xxl-4 mx-auto g-0">
                            {loadingLogin && <Loader />}
                            <div className='icon'>
                                <i className='font-size2' onClick={handleIconClick}><FontAwesomeIcon icon={faTimes}/></i>
                            </div>
                            <div className='logo'>
                                <img src={group} alt="group"/>
                                <p>{t('Welcome to the new Experience')}</p>
                            </div>
                            <div className='div1'>
                                <p>{t('Create your account')}</p>
                            </div>
                            <div className='div2'>
                                <p
                                    className={activeButton === 'login' ? 'active' : ''}
                                    onClick={() => handleButtonClick('login')}
                                >
                                    {t('Log in')}
                                </p>
                                <p
                                    className={activeButton === 'signup' ? 'active' : ''}
                                    onClick={() => handleButtonClick('signup')}
                                >
                                    {t('Sign up')}
                                </p>
                            </div>
                            <form onSubmit={handleSubmit}>

                                <div className='div3'>
                                    <div>
                                        <input
                                            placeholder={t('Please enter email')}
                                            value={loginEmail}
                                            onChange={(e) => setLoginEmail(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='div4'>
                                    <div>
                                        <input
                                            placeholder={t('Password')}
                                            type="password"
                                            value={loginPassword}
                                            onChange={(e) => setLoginPassword(e.target.value)}
                                        />
                                    </div>
                                </div>
                                <div className='div5'>
                                    <p className='font-size1' onClick={showReset}>{t('Forgot Password')}</p>
                                </div>
                                {activeButton === 'login' && loginError && <div className='error-message'>{loginError}</div>}
                                {activeButton === 'signup' && signupError && <div className='error-message'>{signupError}</div>}

                                {errorLogin?.message?.password && <div className='error-message'>{errorLogin?.message?.password }</div>}

                                <div className='div6'>
                                    <div>
                                        <button
                                            className={isFormValid ? 'continue-button valid' : 'continue-button'}
                                            type="submit"
                                        >
                                            {t('CONTINUE')}
                                        </button>
                                    </div>
                                </div>
                                <div className='div7'>
                                    <p>{t('OR LOGIN WITH SOCIAL')}</p>
                                </div>
                            </form>

                            <>
                                <GoogleOAuthProvider clientId="YOUR_GOOGLE_CLIENT_ID" className='custom-google-button'>
                                    <CustomGoogleLoginButton />
                                </GoogleOAuthProvider>
                            </>
                            <>
                                <CustomFacebookLoginButton />
                            </>
                            <div className='div10'>
                                <i>icon</i>
                                {t('Login Instagram')}
                            </div>
                            <div className='div11'>
                                <i>icon</i>
                                {t('Login Apple')}
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* ForgotPassword */}
            {showEmail && (
                <div className='reset container'>
                    <div className='row'>
                        <div className="cont col-12 col-sm-10  col-xl-6 col-md-6 col-lg-5 col-xl-4 col-xxl-4 mx-auto g-0">
                            {loadingSendReset && <Loader />}
                            <div className='icon'>
                                <i className='font-size2' onClick={handleIconClick}><FontAwesomeIcon icon={faTimes}/></i>
                            </div>
                            <div className='logo'>
                                <img src={group} alt="group"/>
                                <p>{t('Welcome to the new Experience')}</p>
                            </div>
                            <div className='div3'>
                                <div>
                                    <input
                                        placeholder={t('Please enter email')}
                                        onChange={(e) => setEmailValid(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='mt mt-4'>
                                <div className='error-message'>{loginErrorReset}</div>
                                <div className='error-message'>{errorSendReset?.message?.message}</div>
                                <div className='div6'>
                                    <div>
                                        <p
                                            className={isFormValidEmail ? 'continue-button valid' : 'continue-button'}
                                            onClick={handleSubmitEmail}
                                        >
                                            {t('CONTINUE')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}


            {showOtpContainer && (
                <div className='otp container'>
                    <div className='row'>
                        <div className="cont col-12 col-sm-10  col-xl-6 col-md-6 col-lg-5 col-xl-4 col-xxl-4 mx-auto g-0">                    
                            {loadingVerifyReset && <Loader />}
                            <div className='icon'>
                                <i className='font-size2' onClick={handleIconClick}><FontAwesomeIcon icon={faTimes}/></i>
                            </div>
                            <div className='logo'>
                                <img src={group} alt="group" />
                                <p>{t('Welcome to the new Experience')}</p>
                            </div>
                            <div className='div1'>
                                <p>{t('VERIFY YOUR EMAIL ADDRESS')}</p>
                            </div>
                            <div className='div13'>
                                <p className='font-size1'>{t('Verify your email using the OTP sent to your email address')}</p>
                            </div>
                            <div className='div14'>
                                <p>{t('Resend OTP')}</p>
                            </div>
                            <div className='div15'>
                                <p>{t('OTP')}</p>
                                <input
                                    className="otp-input"
                                    value={otpReset}
                                    onChange={handleOtpChangeReset}
                                    placeholder="0 0 0 0 0"
                                    maxLength="9"
                                />
                            </div>

                            {otpReset && <div className='error-message'>{errorReset}</div>}
                            <div className='error-message'>{errorVerifyReset?.message}</div>

                            <div className='div6'>
                                <div>
                                    <p
                                        className={`continue-button ${isOtpValid ? 'valid' : ''}`}
                                        onClick={handleOTPSubmitReset}
                                    >
                                        {t('CONTINUE')}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showPasswordContainer && (
                <div className='reset container'>
                    <div className='row'>
                        <div className="cont col-12 col-sm-10  col-xl-6 col-md-6 col-lg-5 col-xl-4 col-xxl-4 mx-auto g-0">                        
                            {loadingresetPassword && <Loader />}
                            <div className='icon'>
                                <i className='font-size2' onClick={handleIconClick}><FontAwesomeIcon icon={faTimes}/></i>
                            </div>
                            <div className='logo'>
                                <img src={group} alt="group"/>
                                <p>{t('Welcome to the new Experience')}</p>
                            </div>
                            <div className='div1'>
                                <p>{t('Create your password')}</p>
                            </div>

                            <div className='div4'>
                                <div>
                                    <input
                                        placeholder={t('Password')}
                                        type="password"
                                        value={Password}
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </div>
                            </div>

                            <div className='div4'>
                                <div>
                                    <input
                                        placeholder={t('Confirm Password')}
                                        type="password"
                                        value={confirmPassword}
                                        onChange={(e) => setConfirmPassword(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='mt-4'>
                                <div className='error-message'>{errorResetPassword}</div>
                                <div className='error-message'>{errorresetPassword?.message}</div>
                                <div className='div6'>
                                    <div>
                                        <p
                                        className={`continue-button ${isPasswordValid ? 'valid' : ''}`}
                                            onClick={handleSubmitPassword}
                                        >
                                            {t('CONTINUE')}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showPopup && (
                <div className="popup">
                    <div className="popup-icon">
                        <i><FontAwesomeIcon icon={faCheck}/></i>
                    </div>
                    <p>{t('Password changed successfully')}</p>
                    <p>{t('Please log in now.')}</p>
                </div>
            )}

                    </div>
                );
            }

export default Register;
