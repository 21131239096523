import '../Brand/Brand';
import './Influencer.css';
import {faCheck, faChevronLeft, faChevronRight, faHeart, faShare, faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { searsh_brand, show_brand, product_reviews, addFavorite, sort_products, influencers_follow, filter_data, sort_data, filter_brducts, category_data, remove_wishlist, add_wishlist, myProfile } from "../Redux/API";
import { productDetails } from "../Redux/HomeSlice";
import Loader from "../Loader";
import {  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TelegramShareButton,
  RedditShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
  LinkedinIcon,
  PinterestIcon,
  TelegramIcon,
  RedditIcon,
} from 'react-share';
import Register from '../Register/Register';
import {showRegister} from "../Redux/HomeSlice";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css'; // استيراد التنسيقات الافتراضية

import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import { Link, NavLink } from 'react-router-dom';


function Influencer() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  


  const gotoCategory = (aut) => {
    navigate('/SubSupCategory');
    dispatch(category_data({aut}));
  };


  // **حالة البحث**
  const [searshBroduct, setSearshBroduct] = useState('');
  const handleInputChangeReturns = (e) => {
    setSearshBroduct(e.target.value); 
  };

  // **تحديث البيانات عند تغيير البحث**
  useEffect(() => {
    if (searshBroduct) {
      dispatch(searsh_brand(searshBroduct)); // استدعاء البحث
      // setFilter_brduct([])
      // setShow_brands(null)
      // setSort_product([])
      // setSearsh_brands(null) 
    } else {
      dispatch(show_brand()); // عرض العلامات التجارية الأصلية
    }
  }, [searshBroduct, dispatch]);

  

  const gotoProductDetails = (product) => {
    if (!product || !product.id) {
      console.error('Product data is missing or invalid');
      return;
    }
    // إرسال البيانات إلى Redux
    dispatch(productDetails(product));
    dispatch(product_reviews(product.id));

    // التنقل إلى صفحة التفاصيل
    navigate('/Product');
  };

const loadingSort_productS = useSelector(state => state.Data.loadingSort_productS);
  const loadingSearsh_brand = useSelector(state => state.Data.loadingSearsh_brand);

  const loadingShow_brand = useSelector(state => state.Data.loadingShow_brand);

  const searsh_brands = useSelector(state => state.Data.searsh_brand);
  const show_brands = useSelector(state => state.Data.show_Influencer);
  const filter_brduct = useSelector(state => state.Data.filter_brducts);
  const sort_product = useSelector(state => state.Data.sort_products);




  const filters_data = useSelector(state => state.Data.filter_data);
  const sorts_data = useSelector(state => state.Data.sort_data);
  const loadingFilter_brduct = useSelector(state => state.Data.loadingFilter_brducts);
  

  // const [searsh_brands, setSearsh_brands] = useState(searsh)
  // const [show_brands, setShow_brands] = useState(show)
  // const [filter_brduct, setFilter_brduct] = useState(filter)
  // const [sort_product, setSort_product] = useState(sort)

  // console.log(show_brands.data.image)
  console.log('44444444444444555555555555555')

  console.log(show_brands?.data?.data?.image)
  console.log(show_brands)
  console.log(filter_brduct)
  console.log(sort_product)
  console.log(searsh_brands)
  const gg = [filter_brduct, show_brands, filter_brduct, sort_product]
  console.log(gg)
  
  const [allProducts, setAllProducts] = useState([]);

  useEffect(() => {
    let updatedProducts = [];
  
    // نبدأ بالعلامات التجارية لو موجودة وتحتوي على مصفوفة
    // if (show_brands?.data?.data?.brand?.products && show_brands?.data?.data?.brand?.products.length > 0) {
    //   updatedProducts = [...show_brands.data.data.brand.products];
    // }
  
    // لو فيه ترتيب تم وبياناته موجودة وفيها مصفوفة منتجات
    if (sort_product?.data?.data?.products && sort_product.data.data.products.length > 0) {
      updatedProducts = [...sort_product.data.data.products];
    }

    // لو فيه فلترة تمت وبياناتها موجودة وفيها مصفوفة منتجات
    if (filter_brduct?.data?.data?.products && filter_brduct.data.data.products.length > 0) {
      updatedProducts = [...filter_brduct.data.data.products]; 
    }

    // لو المستخدم عمل بحث وبيانات البحث موجودة وفيها مصفوفة
    if (searsh_brands?.data?.data && searsh_brands.data.data.length > 0) {
      updatedProducts = [...searsh_brands.data.data];
    }
  
    setAllProducts(updatedProducts);
  }, [show_brands, filter_brduct, sort_product, searsh_brands, loadingSearsh_brand, loadingSort_productS, loadingFilter_brduct, loadingShow_brand]);
  
  console.log(show_brands)
  console.log(sort_product?.data?.data?.products)
  console.log(filter_brduct)
  console.log(searsh_brands)
  console.log(allProducts)
  
  
  
  const [icon, setIcon] = useState(false);
  const [text, setText] = useState(null);
  function handelClick() {
      setIcon(!icon);
  }

  function valueTeaxt(e) {
      setText(e)
  }

  const handleFollow = (id) => {
    dispatch(influencers_follow({ id })); // بعت الـ id للـ backend
  };

  
  useEffect(() => {
    dispatch(filter_data()); 
    dispatch(sort_data()); 
  }, [dispatch]);


  useEffect(() => {
    dispatch(sort_products(text)); 
    // setFilter_brduct(null)
    // setShow_brands([])
    // setSort_product([])
    // setSearsh_brands(null) 
  }, [dispatch, text]);


const influencers_follows = useSelector(state => state.Data.influencers_follow);



const getLocalStorage = window.localStorage.getItem('UserInfo');
const valueLocalStorage = getLocalStorage ? JSON.parse(getLocalStorage) : null;
const token = valueLocalStorage?.data?.token || null;

const showR = useSelector(state => state?.products?.showR);

const loadingUnfollow = useSelector(state => state?.Data.loadingUnfollow);
const add_wishlis = useSelector(state => state.Data.add_wishlist);
console.log(add_wishlis)

const wishlist = useSelector(state => state?.Data?.myProfile?.data?.data?.user?.wishlist || []);
const loadingRemove_wishlist = useSelector((state) => state?.Data.loadingRemove_wishlist);
const loadingAdd_wishlist = useSelector((state) => state?.Data.loadingAdd_wishlist);



// تأكد أن البيانات تُعرض بشكل صحيح في الـ Console
console.log(wishlist);
console.log(wishlist?.map(item => item.id));

useEffect(() => {
    if (!loadingRemove_wishlist && !loadingAdd_wishlist) {
        dispatch(myProfile()); 
    }
}, [dispatch, loadingRemove_wishlist, loadingAdd_wishlist]);

const handelAdd_wishlist = (id) => {
    if (token) {
        const isInWishlist = wishlist.some(item => item.id === id);

        if (isInWishlist) {
            dispatch(remove_wishlist({ id })); 
        } else {
            dispatch(add_wishlist({ id })); 
        }
    } else {
        dispatch(showRegister(true));
    }
};











  const product = ["sofa", 'interior', 'Chairs', 'Tables', 'Chairs', 'Chairs', 'Chairs']

  const [openIndex, setOpenIndex] = useState(null);
  const [selectedFilters, setSelectedFilters] = useState({
    brands: [],
    categories: [],
    prices: [],
    discounts: [],
    colors: [],
    sizes: [],
  });



      const handleFilterDataClick = () => {
        dispatch(filter_brducts({selectedFilters})); 

        // setFilter_brduct([])
        // setShow_brands([])
        // setSort_product(null)
        // setSearsh_brands(null) 
        
      };


        const brands = {
          title: t('brands'),
          subItems: filters_data?.data?.data?.brands,
        };
      
        const items = [
          {
            title: t('categories'),
            key: 'categories', 
            subItems: filters_data?.data?.data?.categories,
          },
          {
            title: t('prices'),
            key: 'prices', 
            subItems: filters_data?.data?.data?.prices,
          },
          {
            title: t('discounts'),
            key: 'discounts', 
            subItems: filters_data?.data?.data?.discounts,
          },
          {
            title: t('colors'),
            key: 'colors', 
            subItems: filters_data?.data?.data?.colors,
          },
          {
            title: t('sizes'),
            key: 'sizes', 
            subItems: filters_data?.data?.data?.sizes,
          },
        ];

        const handleAddFilter = (type, filter) => {
          setSelectedFilters((prevFilters) => {
            const updatedFilters = { ...prevFilters };
      
            if (!Array.isArray(updatedFilters[type])) {
              updatedFilters[type] = [];
            }
      
            if (type === 'brands') {
              if (updatedFilters[type].includes(filter.id)) {
                updatedFilters[type] = updatedFilters[type].filter(id => id !== filter.id);
              } else {
                updatedFilters[type] = [...updatedFilters[type], filter.id];
              }
            } else {
              if (updatedFilters[type].includes(filter.title)) {
                updatedFilters[type] = updatedFilters[type].filter(title => title !== filter.title);
              } else {
                updatedFilters[type] = [...updatedFilters[type], filter.title];
              }
            }
      
            return updatedFilters;
          });
        };
    const icons3 = [['','','',''], ['','',''], ['','']]
    const [openInde, setOpenInde] = useState(2);
    const [selectedIndex, setSelectedIndex] = useState(2);

    const handleRs = (index) => {
      setOpenInde(index)
    };


    const [circles, setCircles] = useState([1, 2, 3]);
    const [backendNumber, setBackendNumber] = useState(50); // أو الرقم اللي هتاخده من الباك اند
    const [activeIndex, setActiveIndex] = useState(0); // لحفظ الدائرة النشطة
  
    const handleRightArrow = () => {
      if (activeIndex < circles.length - 1) {
        setActiveIndex((prevActiveIndex) => prevActiveIndex + 1);
      } else {
        setCircles((prevCircles) => {
          if (prevCircles.every(num => num < backendNumber)) {
            return prevCircles.map(num => num + 1);
          }
          return prevCircles;
        });
      }
    };

  const [likedIndexes, setLikedIndexes] = useState([]);

  const [showShareButtons, setShowShareButtons] = useState(false);
  const toggleShareButtons = () => {
      setShowShareButtons(prev => !prev);
  };

  const copyToClipboard = () => {
      navigator.clipboard.writeText(product?.url || '').then(() => {
          alert('Product link copied to clipboard!');
      }).catch(err => {
          console.error('Failed to copy: ', err);
      });
  };

  return (
    <div className="Brand">
        <Header />
        {showR && <Register />}
        <div className='containerBrand container'>
          <div className='row justify-content-between align-items-end'>
          {loadingShow_brand && <Loader />}
            <div className='col-12 col-sm-5 col-md-5 col-lg-3'>
                <div className='border_box'>
                  <img src={show_brands?.data?.data?.image}/>
                </div>
                <div className='image mt-3'>
                    <div className='border_box_P1'>
                    <span onClick={() => handleFollow(show_brands?.data?.data?.id)}>
                      {t('+Follow')}
                    </span>                    </div>
                    <div className='box_Share' onClick={(copyToClipboard, toggleShareButtons)}>
                        <span className='Share'>
                            <FontAwesomeIcon icon={faShare} />
                        </span>
                    </div>
                </div>
                {showShareButtons && (
                        <div className="shareButtons mt-3 pt-2">
                          <FacebookShareButton className='' url={product?.url || ''}>
                            <FacebookIcon size={32} round />
                          </FacebookShareButton>
                          <TwitterShareButton className='' url={product?.url || ''}>
                            <TwitterIcon size={32} round />
                          </TwitterShareButton>
                          <WhatsappShareButton className='' url={product?.url || ''}>
                            <WhatsappIcon size={32} round />
                          </WhatsappShareButton>
                          <LinkedinShareButton className='' url={product?.url || ''}>
                            <LinkedinIcon size={32} round />
                          </LinkedinShareButton>
                          <PinterestShareButton className='' url={product?.url || ''}>
                            <PinterestIcon size={32} round />
                          </PinterestShareButton>
                          <TelegramShareButton className='' url={product?.url || ''}>
                            <TelegramIcon size={32} round />
                          </TelegramShareButton>
                        </div>
                      )}
            </div>
            <div className='col-12 col-sm-5 col-md-5 col-lg-3 justify-content-around'>
                <h2>{show_brands?.data?.data?.name}</h2>
                <div className=''>
                  <input type="text" value={searshBroduct} onChange={handleInputChangeReturns} 
                  placeholder={t('products search...')}
                />               
                </div>
            </div>
            <div className='col-12 col-sm-12 col-md-12 col-lg-4 mt-sm-2 p-1'>
              <ul style={{ margin: 0, padding: '20px 30px', backgroundColor: 'white', borderRadius: '20px', width: '100%', height: '100%', textAlign: 'start' }}>
              <PerfectScrollbar style={{ height: '100%' }} options={{ suppressScrollX: true }}>
              <p className='font-size2 pt-2'>{t('Store categories')}</p>
              {show_brands?.data?.data?.categories.map((e, index) => (
                  <li key={index} style={{ marginTop: '10px', color: 'black', width: '100%', fontWeight: '400', padding: '1%', cursor: 'pointer' }} onClick={() => gotoCategory(e.name)}>
                    {e.name}
                  </li>
                ))}
            </PerfectScrollbar>
              </ul>
            </div>
          </div>
        </div>
        <span className='hr'></span>













        <div className='containe container'>
          <div className='border_box2'>
            <div className='background'>
            <p className='Produ'>{filters_data?.data?.data?.total_products} {t('Products')}</p>
            <div>
                {icons3.map((e, i) => (
                  <div key={i}    className={
                    e.length === 2 
                      ? 'show-on-small1' 
                      : e.length === 3 
                      ? 'show-on-small2' 
                      : e.length === 4 
                      ? 'show-on-small3' 
                      : ''
                  }>
                    <p
                      className={`p ${openInde === i ? 'selected' : ''}`}
                      onClick={() => handleRs(i)}
                    >
                      {e.map((item, index) => (
                        <span key={index} className={openInde === i ? 'selected' : ''}>
                          {item}
                        </span>
                      ))}
                    </p>
                    {openInde === i && (
                      <span
                        className="arrow"
                        style={{
                          marginInlineStart:
                            document.documentElement.dir === 'rtl'
                              ? '-7px'
                              : openInde === 0 || openInde === 1 || openInde === 2
                              ? '-25px'
                              : '',
                        }}
                      >
                        &#9650;
                      </span>
                    )}
                  </div>
                ))}
              </div>

              <div className="boxList">
                <div className="level">
                <p>{t('Sort by')}: <span>{text}</span></p>
                <i onClick={handelClick} className={`${icon ? 'activ' : 'noActiv'}`}><FontAwesomeIcon icon={faChevronRight}/></i>
                </div>

                {icon &&
                  <div className="levels">
                    {sorts_data?.data?.data?.filter_titles.map((e, i) => 
                      <p key={i} onClick={() => (valueTeaxt(e), setIcon(!icon))}>{e}</p>
                    )}
                  </div>
                }
              </div>
          </div>
          </div>
        </div>



        <div className="main-container container">
            <div className="main-cont col-4" style={{ display: openInde === 0 || openInde === 1 ? 'none' : 'block' }}>
            <div className="Filter_icon">
          <p>Filter</p>
          <i className="i" onClick={handleFilterDataClick}>
            <FontAwesomeIcon icon={faCheck} />
          </i>
        </div>

        {/* عرض البراندات */}
        <div className="main-item">
          <div className="item-headerr" onClick={() => setOpenIndex(openIndex === 0 ? null : 0)}>
            <p>{brands.title}</p>
            <p className="arrow">
              <i>
                <FontAwesomeIcon icon={faChevronRight} />
              </i>
            </p>
          </div>
          {openIndex === 0 && (
            <div className="sub-items">
               {brands?.subItems?.map((brand, brandIndex) => (
                <div
                  key={brandIndex}
                  className="sub-item d-flex justify-content-between hover-bg"
                  onClick={() => handleAddFilter('brands', { id: brand.id })}
                >
                  <p>{brand.filter_title}</p>
                    <div className='m d-flex '>
                      <p className='mx-2'>{brand.quantity}</p>
                      {selectedFilters.brands?.includes(brand.id) && <span>✅</span>}
                    </div>
                </div>
              ))}

            </div>
          )}
        </div>

        {items?.map((item, index) => (
          <div key={index} className="main-item">
            <div className="item-headerr" onClick={() => setOpenIndex(openIndex === index + 1 ? null : index + 1)}>
              <p>{item.title}</p>
              <p className="arrow">
                <i>
                  <FontAwesomeIcon icon={faChevronRight} />
                </i>
              </p>
            </div>
            {openIndex === index + 1 && (
              <div className="sub-items">
                  {item?.subItems?.map((subItem, subIndex) => (
                    <div
                      key={subIndex}
                      className="sub-item d-flex justify-content-between hover-bg"
                      onClick={() => handleAddFilter(item.key, { title: subItem.filter_title })}
                    >
                      <p>{subItem.filter_title}</p>
                      <div className='m d-flex '>
                      <p className='mx-2'>{subItem.quantity}</p>
                      {selectedFilters[item.key]?.includes(subItem.filter_title) && <span>✅</span>}
                      </div>
                    </div>
                  ))}

              </div>
            )}
          </div>
        ))}
      </div>
    {/* </div> */}


            <div className="gallery1 container">
            <div className="row align-items-center justify-content-around g-0">
              {loadingSearsh_brand && <Loader />}
              {loadingSort_productS && <Loader />}
              {loadingFilter_brduct && <Loader />}
              {loadingShow_brand && <Loader />}
               
              {allProducts.map((product, index) => {
    // تحقق ما إذا كان المنتج موجود في قائمة الأمنيات
    const isInWishlist = wishlist.some(item => item.id === product.id);

    return (
        <div className={`box col-xxl-${ openInde === 0 ? 3 : openInde === 1 ? 3 : openInde === 2 ? 5 : 12} px-xxl-${ openInde === 0 ? 5 : openInde === 1 ? 5 : openInde === 2 ? 5 : 0} mx-xxl-${ openInde === 0 ? 0 : openInde === 1 ? 2 : openInde === 2 ? 3 : 0}
        col-xl-${ openInde === 0 ? 3 : openInde === 1 ? 3 : openInde === 2 ? 5 : 12} px-xl-${ openInde === 0 ? 4 : openInde === 1 ? 3 : openInde === 2 ? 3 : 0} mx-xl-${ openInde === 0 ? 0 : openInde === 1 ? 2 : openInde === 2 ? 3 : 0}
      col-lg-${ openInde === 0 ? 3 : openInde === 1 ? 4 : openInde === 2 ? 5 : 12} px-lg-${ openInde === 0 ? 2 : openInde === 1 ? 5 : openInde === 2 ? 1 : 0}
      col-md-${ openInde === 0 ? 4 : openInde === 1 ? 4 : openInde === 2 ? 6 : 12} px-md-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 1 : 0}
      col-sm-${ openInde === 0 ? 4 : openInde === 1 ? 5 : openInde === 2 ? 11 : 12} px-sm-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 4 : 0}
      col-xs${ openInde === 0 ? 5 : openInde === 1 ? 5 : openInde === 2 ? 8 : 12} px-xs-${ openInde === 0 ? 1 : openInde === 1 ? 3 : openInde === 2 ? 4 : 0}
        text-center g-0 mb-3`}>
            <div className="border_box">
                <div className="padding_box">
                    {/* الأيقونة مع تغيير لونها بناءً على وجود المنتج في قائمة الأمنيات */}
                    <i onClick={() => handelAdd_wishlist(product.id)}>
                        <FontAwesomeIcon icon={faHeart} style={{ color: isInWishlist ? 'red' : 'white' }} />
                    </i>
                    {product?.discount && <p className="discount">Off {product?.discount}</p>}
                    {product.main_image && (
                        <img
                            onClick={() => gotoProductDetails(product)}
                            src={product.main_image}
                            alt={`img-${index}`}
                        />
                    )}
                    <p className="discraptoin">{product?.description}</p>
                    <div className="price align-items-center">
                        <p>{product?.new_price}</p>
                        {product?.old_price && <p className="old_price">{product?.old_price}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
})}
              </div>
       
      </div>
     
        </div>
        <Footer />
    </div>
  );
}

export default Influencer;