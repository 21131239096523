import './Product.css';
import { useEffect, useState} from "react";
import { faCheck, faHeart, faShare, faStar, faTruck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { product_reviews, postReviews, post_cart, myProfile } from '../Redux/API';
import { remove_wishlist, add_wishlist } from "../Redux/API";
import { showRegister } from "../Redux/HomeSlice";
import Register from '../Register/Register';
import Loader from "../Loader";

import {
    FacebookShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    LinkedinShareButton,
    PinterestShareButton,
    TelegramShareButton,
    RedditShareButton,
    FacebookIcon,
    TwitterIcon,
    WhatsappIcon,
    LinkedinIcon,
    PinterestIcon,
    TelegramIcon,
    RedditIcon,
} from 'react-share';
import StarRatings from 'react-star-ratings';

import Header from '../Header/Header';
import Footer from '../Footer/Footer';

function Product() {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    
    const product = useSelector(state => state?.products?.product);
    const quantity = product?.product_varients?.[0]?.quantity || 0;
    const product_review = useSelector(state => state?.Data?.product_reviews);
    const rating = product_review?.data?.data?.reviews_avg;
    const ratings = product_review?.data?.data?.ratings;

    const [selectedItem, setSelectedItem] = useState(product?.main_image || []);
    const [activ, setActiv] = useState(0);
    const [selectedColors, setSelectedColors] = useState([]);
    const [selectedSizes, setSelectedSizes] = useState([]);
    const [counter, setCounter] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');

    const getLocalStorage = window.localStorage.getItem('UserInfo');
    const valueLocalStorage = getLocalStorage ? JSON.parse(getLocalStorage) : null;
    const token = valueLocalStorage?.data?.token || null;
  
    const showR = useSelector(state => state?.products?.showR);
    const post_carts = useSelector(state => state.Data.post_cart);
    const loadingPost_cart = useSelector(state => state.Data.loadingPost_cart);
    
    // دالة لاختيار الصور
    function handelImgeClik(image, index){
        setSelectedItem(image);
        setActiv(index);
    }

    const toggleColorSelection = (colorId) => {
        setSelectedColors(prevColors => 
            prevColors.includes(colorId) 
                ? prevColors.filter(id => id !== colorId) // لو اللون موجود نشيله
                : [...prevColors, colorId] // لو مش موجود نضيفه
        );
    };

    const toggleSizeSelection = (sizeId) => {
        setSelectedSizes(prevSizes => 
            prevSizes.includes(sizeId) 
                ? prevSizes.filter(id => id !== sizeId) // لو المقاس موجود نشيله
                : [...prevSizes, sizeId] // لو مش موجود نضيفه
        );
    };

    const increment = () => {
        setCounter(prevCounter => (prevCounter < quantity ? prevCounter + 1 : prevCounter));
    };

    const decrement = () => {
        setCounter(prevCounter => (prevCounter > 1 ? prevCounter - 1 : 0));
    };

    const handleAddToBasket = () => {
        // التحقق من اختيار الألوان
        if (!selectedColors.length) {
            setErrorMessage('من فضلك اختر لون.'); 
            return;
        }

        // التحقق من اختيار المقاسات
        if (!selectedSizes.length) {
            setErrorMessage('من فضلك اختر مقاس.'); 
            return;
        }

        // التحقق من اختيار الكمية
        if (counter === 0) {
            setErrorMessage('من فضلك اختر الكمية.'); 
            return;
        }

        // التحقق إذا كان المستخدم مسجل أو لأ
        if (token) {
            // المستخدم مسجل، نقدر نضيف للسلة
            dispatch(post_cart({
                id: product.id,
                colors: selectedColors, 
                sizes: selectedSizes,
                quantity: counter 
            }));
        } else {
            // المستخدم مش مسجل، عرض فورم التسجيل
            dispatch(showRegister(true));
        }
    };

    const wishlist = useSelector(state => state?.Data?.myProfile?.data?.data?.user?.wishlist || []);
    const loadingRemove_wishlist = useSelector((state) => state?.Data.loadingRemove_wishlist);
    const loadingAdd_wishlist = useSelector((state) => state?.Data.loadingAdd_wishlist);

    useEffect(() => {
        if (!loadingRemove_wishlist && !loadingAdd_wishlist) {
            dispatch(myProfile());
        }
    }, [dispatch, loadingRemove_wishlist, loadingAdd_wishlist]);

    const handelAdd_wishlist = (id) => {
        if (token) {
            const isInWishlist = wishlist.some(item => item.id === id);
    
            if (isInWishlist) {
                dispatch(remove_wishlist({ id }));
            } else {
                dispatch(add_wishlist({ id })); 
            }
        } else {
            dispatch(showRegister(true));
        }
    };

    const [showShareButtons, setShowShareButtons] = useState(false);
    const toggleShareButtons = () => {
        setShowShareButtons(prev => !prev);
    };

    const loadingProduct_reviews = useSelector(state => state.Data.loadingProduct_reviews);
    const loadingPostReviews = useSelector(state => state.Data.loadingPostReviews);
    const [showRating, setShowRating] = useState(false);
    const FshowRating = () => {
        setShowRating(!showRating);
    }

    const [rat, setRat] = useState(0);

    const changeRating = async (newRating) => {
        setRat(newRating);

        const result = await dispatch(postReviews({ product_id: product?.id, rating: newRating }));

        if (result?.payload?.status === 200) {
            setShowRating(!showRating); 
        } else {
            console.error("Failed to submit review.");
        }
    };

    return (
        <div className="Product">
            <Header />
            {showR && <Register />} {/* عرض فورم التسجيل لو المستخدم مش مسجل */}
            {loadingPost_cart && <Loader />}
            {loadingPostReviews && <Loader />}
            <div className={`container_Product container`}>
                <div className='row justify-content-between mt-5'>
                    <div className="box_image col-12 col-md-5 col-lg-5">
                        <div>
                            <img className='img' src={selectedItem} />
                            <i
                                className='i'
                                onClick={() => handelAdd_wishlist(product?.id)} 
                                style={{ color: wishlist.some(item => item.id === product?.id) ? 'red' : 'white' }} 
                            >
                                <FontAwesomeIcon icon={faHeart} />
                            </i>
                        </div>
                        <div className="div_counter">
                            <div className="div_images">
                                {product?.images?.length > 0 && product.images.map((image, index) => (
                                    <img src={image?.image} key={index} className={activ === index ? 'activ' : ''} onClick={() => handelImgeClik(image?.image, index)} />
                                ))}
                            </div>
                        </div> 
                    </div>
                    <div className={`box_data col-12 col-md-5 col-lg-4`}>
                    <p>{product?.name}</p>
                     <p>{product?.description}</p>
                     <p className='price'>{product?.new_price} KD <span> <span></span>{product?.old_price} KD</span></p>
                     <div className='check'>
                         <i className='i'><FontAwesomeIcon icon={faCheck} /></i>
                         <p>{product?.status}</p>
                     </div>
                     <div className='Color'>
                        <p>Color</p>
                        <div className='imgs'>
                            {product?.colors?.map((color, index) => (
                                <img 
                                    src={color?.image} 
                                    key={index} 
                                    className={selectedColors.includes(color?.id) ? 'selected' : ''} 
                                    onClick={() => toggleColorSelection(color?.id)} 
                                />
                            ))}
                        </div>
                    </div>

                        {/* لستة المقاسات */}
                        <div className='Size'>
                            <p>Size</p>
                            <div>
                                {product?.sizes?.map((size, index) => (
                                    <p 
                                        key={index} 
                                        className={selectedSizes.includes(size?.id) ? 'selected' : ''} 
                                        onClick={() => toggleSizeSelection(size?.id)} 
                                    >
                                        {size?.size}
                                    </p>
                                ))}
                            </div>
                        </div>
                        <div className={`Quantity`}>
                            <p>Quantity</p>
                            <div className="counter">
                                <span className='span1' onClick={decrement}>-</span>
                                <span className='span3'>{counter}</span>
                                <span className='span2' onClick={increment}>+</span>
                            </div>
                        </div>

                        {errorMessage && <p className="error-message">{errorMessage}</p>}
                        <div className={`add`}>
                            <p 
                                className={counter > 0 ? 'active' : ''} 
                                onClick={handleAddToBasket}
                            >
                                Add to Basket
                            </p>
                            <p 
                                className={counter > 0 ? 'active' : ''} 
                                onClick={handleAddToBasket}
                            >
                                Buy Now
                            </p>
                        </div>
                    </div>

                    <div className={`box_brand col-12 col-lg-2 mt-md-5 mt-sm-4 justify-content-between`}>
                        <div>
                            <i className='i' onClick={toggleShareButtons}>
                                <FontAwesomeIcon icon={faShare} />
                            </i>
                            {showShareButtons && (
                                <div className="share-buttons">
                                    <FacebookShareButton className='button' url={product?.url || ''}>
                                        <FacebookIcon size={32} round />
                                    </FacebookShareButton>
                                    <TwitterShareButton className='button' url={product?.url || ''}>
                                        <TwitterIcon size={32} round />
                                    </TwitterShareButton>
                                    <WhatsappShareButton className='button' url={product?.url || ''}>
                                        <WhatsappIcon size={32} round />
                                    </WhatsappShareButton>
                                    <LinkedinShareButton className='button' url={product?.url || ''}>
                                        <LinkedinIcon size={32} round />
                                    </LinkedinShareButton>
                                    <PinterestShareButton className='button' url={product?.url || ''}>
                                        <PinterestIcon size={32} round />
                                    </PinterestShareButton>
                                    <TelegramShareButton className='button' url={product?.url || ''}>
                                        <TelegramIcon size={32} round />
                                    </TelegramShareButton>
                                </div>
                            )}
                        </div>
                        <div className='col-5 col-lg-12'>
                            <p>Store : {product?.brand}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`container_data container`}>
                <div className='row justify-content-between g-0'>
                    <div className='details col-12 col-lg-5'>
                        <p className='detailsP'>Product Details</p>
                        <p className='detailsP'>Material</p>
                        <p className='detailsP2'>
                            <span>Material</span>
                            <span>{product?.product_varients?.[0]?.material}</span>
                        </p>
                        <p className='detailsP2'>
                            <span>Material Composition</span>
                            <span>{product?.product_varients?.[0]?.material_composition}</span>
                        </p>
                        <p className='detailsP'>Warranty & Care</p>
                        <p className='detailsP2'>
                            <span>Care Instructions</span>
                            <span>{product?.product_varients?.[0]?.care_instructions}</span>
                        </p>
                        <p className='detailsP'>General Specifications</p>
                        <p className='detailsP2'>
                            <span>Nature's Comfort</span>
                            <span>{product?.product_varients?.[0]?.nature_comfort}</span>
                        </p>
                        <p className='detailsP2'>
                            <span>Occasion</span>
                            <span>{product?.product_varients?.[0]?.occasion}</span>
                        </p>
                        <p className='detailsP2'>
                            <span>Model</span>
                            <span>{product?.product_varients?.[0]?.model}</span>
                        </p>
                    </div>
                    <div className='Shipping_Reviews col-12 col-lg-6'>
                        <div className='Shipping'>
                            <p className='ShippingP'>Shipping</p>
                            {product?.shippings?.length > 0 && product.shippings.map((shipping, index) => (
                                <p className='ShippingP' key={index}>
                                    <i className='i'><FontAwesomeIcon icon={faTruck} /></i>
                                    {shipping?.key}: <span>{shipping?.value}</span>
                                </p>
                            ))}
                        </div>
                        <div className='Reviews'>
                            <p className='pr'>Reviews</p>
                            <div className='ReviewsD justify-content-between align-items-center'>
                                <div>
                                    <p>Average Rating</p>
                                    <p className='p5'>{rating}</p>
                                    <p>Out of <span>{rating}</span> stars</p>
                                </div>
                                <div className="star-container">
                                    {[
                                        { stars: 5, count: ratings?.five_star },
                                        { stars: 4, count: ratings?.four_star },
                                        { stars: 3, count: ratings?.three_star },
                                        { stars: 2, count: ratings?.two_star },
                                        { stars: 1, count: ratings?.one_star },
                                    ].map((ratingItem, index) => (
                                        <div
                                            key={index}
                                            className={`star-row ${rating === ratingItem?.stars ? 'highlight-stars' : ''}`}
                                        >
                                            <div className="stars">
                                                {Array.from({ length: ratingItem?.stars }).map((_, i) => (
                                                    <FontAwesomeIcon key={i} icon={faStar} className='mx-1' />
                                                ))}
                                            </div>
                                            <div className="line"></div>
                                            <span className="star-count">{ratingItem?.count}</span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='button mt-4'>
                                <p className='button1'>What do you think about this product?</p>
                                <p className='button2' onClick={FshowRating}>Write a Review</p>
                            </div>
                            {showRating && (
                                <div className='StarRatings font-size2'>
                                    <StarRatings
                                        rating={rat}
                                        starRatedColor="gold"
                                        changeRating={changeRating}
                                        numberOfStars={5}
                                        name='rating'
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='Customer'><span>20</span> Customer reviews and <span>8</span> Customer ratings</div>
            </div>
            <Footer />
        </div>
    );
}

export default Product;
// import './Product.css';
// import { useEffect, useState} from "react";
// import { faCheck, faHeart, faShare, faStar, faTruck } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useTranslation } from 'react-i18next';
// import { useSelector } from 'react-redux';
// import { product_reviews, postReviews, post_cart, myProfile } from '../Redux/API';
// import { remove_wishlist, add_wishlist } from "../Redux/API";
// import { useDispatch } from 'react-redux';
// import {showRegister} from "../Redux/HomeSlice";
// import Register from '../Register/Register';
// import Loader from "../Loader";


// import {
//     FacebookShareButton,
//     TwitterShareButton,
//     WhatsappShareButton,
//     LinkedinShareButton,
//     PinterestShareButton,
//     TelegramShareButton,
//     RedditShareButton,
//     FacebookIcon,
//     TwitterIcon,
//     WhatsappIcon,
//     LinkedinIcon,
//     PinterestIcon,
//     TelegramIcon,
//     RedditIcon,
// } from 'react-share';
// import StarRatings from 'react-star-ratings';

// import Header from '../Header/Header';
// import Footer from '../Footer/Footer';

// function Product() {
//     const dispatch = useDispatch();

//     const { t } = useTranslation();

//     const product = useSelector(state => state?.products?.product);
    
//     console.log(product)

//     const quantity = product?.product_varients?.[0]?.quantity || 0;

//     const product_review = useSelector(state => state?.Data?.product_reviews);
//     const rating = product_review?.data?.data?.reviews_avg;
//     const ratings = product_review?.data?.data?.ratings;

//     const [selectedItem, setSelectedItem] = useState(product?.main_image || []);
//     const [activ, setActiv] = useState(0);

//     function handelImgeClik(image, index){
//         setSelectedItem(image);
//         setActiv(index);
//     }

//     const [selectedColors, setSelectedColors] = useState([]);

//     const toggleColorSelection = (colorId) => {
//         setSelectedColors(prevColors => 
//             prevColors.includes(colorId) 
//                 ? prevColors.filter(id => id !== colorId) // لو اللون موجود نشيله
//                 : [...prevColors, colorId] // لو مش موجود نضيفه
//         );
//     };
    

//     const [selectedSizes, setSelectedSizes] = useState([]);

//     const toggleSizeSelection = (sizeId) => {
//         setSelectedSizes(prevSizes => 
//             prevSizes.includes(sizeId) 
//                 ? prevSizes.filter(id => id !== sizeId) // لو المقاس موجود نشيله
//                 : [...prevSizes, sizeId] // لو مش موجود نضيفه
//         );
//     };

    
//     const increment = () => {
//         setCounter(prevCounter => (prevCounter < quantity ? prevCounter + 1 : prevCounter));
//     };

//     const decrement = () => {
//         setCounter(prevCounter => (prevCounter > 1 ? prevCounter - 1 : 0));
//     };

//     const [counter, setCounter] = useState(0);
//     const [errorMessage, setErrorMessage] = useState('');


//     const getLocalStorage = window.localStorage.getItem('UserInfo');
//     const valueLocalStorage = getLocalStorage ? JSON.parse(getLocalStorage) : null;
//     const token = valueLocalStorage?.data?.token || null;
  
//     const showR = useSelector(state => state?.products?.showR);
    
//     const post_carts = useSelector(state => state.Data.post_cart);
//     const loadingPost_cart = useSelector(state => state.Data.loadingPost_cart);
//     console.log(post_carts)
//     const handleAddToBasket = () => {
//         if (!selectedColors.length) {
//             setErrorMessage('من فضلك اختر لون.'); 
//             return;
//         }
    
//         if (!selectedSizes.length) {
//             setErrorMessage('من فضلك اختر مقاس.'); 
//             return;
//         }
    
//         if (counter === 0) {
//             setErrorMessage('من فضلك اختر الكمية.'); 
//             return;
//         }
//         if (token) {
//             dispatch(post_cart({
//                 id: product.id,
//                 colors: selectedColors, 
//                 sizes: selectedSizes,
//                 quantity: counter 
//             }));
//         } else {
//             dispatch(showRegister(true));
//         }
//     };

//     const wishlist = useSelector(state => state?.Data?.myProfile?.data?.data?.user?.wishlist || []);
//     const loadingRemove_wishlist = useSelector((state) => state?.Data.loadingRemove_wishlist);
//     const loadingAdd_wishlist = useSelector((state) => state?.Data.loadingAdd_wishlist);


//     useEffect(() => {
//         if (!loadingRemove_wishlist && !loadingAdd_wishlist) {
//             dispatch(myProfile());
//         }
//     }, [dispatch, loadingRemove_wishlist, loadingAdd_wishlist]);
    
//     const handelAdd_wishlist = (id) => {
//         if (token) {
//             const isInWishlist = wishlist.some(item => item.id === id);
    
//             if (isInWishlist) {
//                 dispatch(remove_wishlist({ id }));
//             } else {
//                 dispatch(add_wishlist({ id })); 
//             }
//         } else {
//             dispatch(showRegister(true));
//         }
//     };


//     const [showShareButtons, setShowShareButtons] = useState(false);
//     const toggleShareButtons = () => {
//         setShowShareButtons(prev => !prev);
//     };

//     const loadingProduct_reviews = useSelector(state => state.Data.loadingProduct_reviews);
//     const loadingPostReviews = useSelector(state => state.Data.loadingPostReviews);

//     const [showRating, setShowRating] = useState(false);
//     const FshowRating = () => {
//         setShowRating(!showRating);
//     }

//     const [rat, setRat] = useState(0);


//     // دالة لتغيير الريفيو وإرسال الطلب
//     const changeRating = async (newRating) => {
//         setRat(newRating);

//         const result = await dispatch(postReviews({ product_id: product?.id, rating: newRating }));

//         if (result?.payload?.status === 200) {
//             setShowRating(!showRating); 
//         } else {
//             console.error("Failed to submit review.");
//         }
//     };

//     return (
//         <div className="Product">
//             <Header />
//             {showR && <Register />}
//             {loadingPost_cart && <Loader />}
//             {loadingPostReviews && <Loader />}
//             <div className={`container_Product container`}>
//                 <div className='row justify-content-between mt-5'>
//                     <div className="box_image col-12 col-md-5 col-lg-5">
//                         <div>
//                             <img className='img' src={selectedItem} />
//                             <i
//                                 className='i'
//                                 onClick={() => handelAdd_wishlist(product?.id)} // استدعاء الدالة مع الـ id الخاص بالمنتج
//                                 style={{ color: wishlist.some(item => item.id === product?.id) ? 'red' : 'white' }} // تغيير لون القلب حسب حالة المنتج
//                             >
//                                 <FontAwesomeIcon icon={faHeart} />
//                             </i>
//                         </div>
//                         <div className="div_counter">
//                             <div className="div_images">
//                                 {product?.images?.length > 0 && product.images.map((image, index) => (
//                                     <img src={image?.image} key={index} className={activ === index ? 'activ' : ''} onClick={() => handelImgeClik(image?.image, index)} />
//                                 ))}
//                             </div>
//                         </div> 
//                     </div>
//                     <div className={`box_data col-12 col-md-5 col-lg-4`}>
//                     <p>{product?.name}</p>
//                      <p>{product?.description}</p>
//                      <p className='price'>{product?.new_price} KD <span> <span></span>{product?.old_price} KD</span></p>
//                      <div className='check'>
//                          <i className='i'><FontAwesomeIcon icon={faCheck} /></i>
//                          <p>{product?.status}</p>
//                      </div>
//                      <div className='Color'>
//                         <p>Color</p>
//                         <div className='imgs'>
//                             {product?.colors?.map((color, index) => (
//                                 <img 
//                                     src={color?.image} 
//                                     key={index} 
//                                     className={selectedColors.includes(color?.id) ? 'selected' : ''} 
//                                     onClick={() => toggleColorSelection(color?.id)} // هنا بنبدل اختيار اللون
//                                 />
//                             ))}
//                         </div>
//                     </div>

//                         {/* لستة المقاسات */}
//                         <div className='Size'>
//                             <p>Size</p>
//                             <div>
//                                 {product?.sizes?.map((size, index) => (
//                                     <p 
//                                         key={index} 
//                                         className={selectedSizes.includes(size?.id) ? 'selected' : ''} 
//                                         onClick={() => toggleSizeSelection(size?.id)} // هنا بنبدل اختيار المقاس
//                                     >
//                                         {size?.size}
//                                     </p>
//                                 ))}
//                             </div>
//                         </div>
//                         <div className={`Quantity`}>
//                             <p>Quantity</p>
//                             <div className="counter">
//                                 <span className='span1' onClick={decrement}>-</span>
//                                 <span className='span3'>{counter}</span>
//                                 <span className='span2' onClick={increment}>+</span>
//                             </div>
//                         </div>

//                         {errorMessage && <p className="error-message">{errorMessage}</p>}
//                         <div className={`add`}>
//                             <p 
//                                 className={counter > 0 ? 'active' : ''} 
//                                 onClick={handleAddToBasket}
//                             >
//                                 Add to Basket
//                             </p>
//                             <p 
//                                 className={counter > 0 ? 'active' : ''} 
//                                 onClick={handleAddToBasket}
//                             >
//                                 Buy Now
//                             </p>
//                         </div>
//                     </div>

//                     <div className={`box_brand col-12 col-lg-2 mt-md-5 mt-sm-4 justify-content-between`}>
//                         <div>
//                             <i className='i' onClick={toggleShareButtons}>
//                                 <FontAwesomeIcon icon={faShare} />
//                             </i>
//                             {showShareButtons && (
//                                 <div className="share-buttons">
//                                     <FacebookShareButton className='button' url={product?.url || ''}>
//                                         <FacebookIcon size={32} round />
//                                     </FacebookShareButton>
//                                     <TwitterShareButton className='button' url={product?.url || ''}>
//                                         <TwitterIcon size={32} round />
//                                     </TwitterShareButton>
//                                     <WhatsappShareButton className='button' url={product?.url || ''}>
//                                         <WhatsappIcon size={32} round />
//                                     </WhatsappShareButton>
//                                     <LinkedinShareButton className='button' url={product?.url || ''}>
//                                         <LinkedinIcon size={32} round />
//                                     </LinkedinShareButton>
//                                     <PinterestShareButton className='button' url={product?.url || ''}>
//                                         <PinterestIcon size={32} round />
//                                     </PinterestShareButton>
//                                     <TelegramShareButton className='button' url={product?.url || ''}>
//                                         <TelegramIcon size={32} round />
//                                     </TelegramShareButton>
//                                 </div>
//                             )}
//                         </div>
//                         <div className='col-5 col-lg-12'>
//                             <p>Store : {product?.brand}</p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             <div className={`container_data container`}>
//                 <div className='row justify-content-between g-0'>
//                     <div className='details col-12 col-lg-5'>
//                         <p className='detailsP'>Product Details</p>
//                         <p className='detailsP'>Material</p>
//                         <p className='detailsP2'>
//                             <span>Material</span>
//                             <span>{product?.product_varients?.[0]?.material}</span>
//                         </p>
//                         <p className='detailsP2'>
//                             <span>Material Composition</span>
//                             <span>{product?.product_varients?.[0]?.material_composition}</span>
//                         </p>
//                         <p className='detailsP'>Warranty & Care</p>
//                         <p className='detailsP2'>
//                             <span>Care Instructions</span>
//                             <span>{product?.product_varients?.[0]?.care_instructions}</span>
//                         </p>
//                         <p className='detailsP'>General Specifications</p>
//                         <p className='detailsP2'>
//                             <span>Nature's Comfort</span>
//                             <span>{product?.product_varients?.[0]?.nature_comfort}</span>
//                         </p>
//                         <p className='detailsP2'>
//                             <span>Occasion</span>
//                             <span>{product?.product_varients?.[0]?.occasion}</span>
//                         </p>
//                         <p className='detailsP2'>
//                             <span>Model</span>
//                             <span>{product?.product_varients?.[0]?.model}</span>
//                         </p>
//                     </div>
//                     <div className='Shipping_Reviews col-12 col-lg-6'>
//                         <div className='Shipping'>
//                             <p className='ShippingP'>Shipping</p>
//                             {product?.shippings?.length > 0 && product.shippings.map((shipping, index) => (
//                                 <p className='ShippingP' key={index}>
//                                     <i className='i'><FontAwesomeIcon icon={faTruck} /></i>
//                                     {shipping?.key}: <span>{shipping?.value}</span>
//                                 </p>
//                             ))}
//                         </div>
//                         <div className='Reviews'>
//                             <p className='pr'>Reviews</p>
//                             <div className='ReviewsD justify-content-between align-items-center'>
//                                 <div>
//                                     <p>Average Rating</p>
//                                     <p className='p5'>{rating}</p>
//                                     <p>Out of <span>{rating}</span> stars</p>
//                                 </div>
//                                 <div className="star-container">
//                                     {[
//                                         { stars: 5, count: ratings?.five_star },
//                                         { stars: 4, count: ratings?.four_star },
//                                         { stars: 3, count: ratings?.three_star },
//                                         { stars: 2, count: ratings?.two_star },
//                                         { stars: 1, count: ratings?.one_star },
//                                     ].map((ratingItem, index) => (
//                                         <div
//                                             key={index}
//                                             className={`star-row ${rating === ratingItem?.stars ? 'highlight-stars' : ''}`}
//                                         >
//                                             <div className="stars">
//                                                 {Array.from({ length: ratingItem?.stars }).map((_, i) => (
//                                                     <FontAwesomeIcon key={i} icon={faStar} className='mx-1' />
//                                                 ))}
//                                             </div>
//                                             <div className="line"></div>
//                                             <span className="star-count">{ratingItem?.count}</span>
//                                         </div>
//                                     ))}
//                                 </div>
//                             </div>
//                             <div className='button mt-4'>
//                                 <p className='button1'>What do you think about this product?</p>
//                                 <p className='button2' onClick={FshowRating}>Write a Review</p>
//                             </div>
//                             {showRating && (
//                                 <div className='StarRatings font-size2'>
//                                     <StarRatings
//                                         rating={rat}
//                                         starRatedColor="gold"
//                                         changeRating={changeRating}
//                                         numberOfStars={5}
//                                         name='rating'
//                                     />
//                                 </div>
//                             )}
//                         </div>
//                     </div>
//                 </div>
//                 <div className='Customer'><span>20</span> Customer reviews and <span>8</span> Customer ratings</div>
//             </div>
//             <Footer />
//         </div>
//     );
// }

// export default Product;
